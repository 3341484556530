import API from '@axios'

const API_URL = '/api/crm/crm_relationship_hierarchy'

export default {
  getAll() {
    return API.get(`${API_URL}`)
  },
  getAllByTableName(tableName) {
    return API.get(`${API_URL}/${tableName}`)
  },

}
