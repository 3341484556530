import { FieldType } from '@/constants/form'
import CustomFieldTypes from '@/constants/customFieldTypes'

export function formatStateAsCreationPayload({ form, fieldInputs, bannerMimeType }) {
  const questionFields = []
  const titleFields = []

  fieldInputs = fieldInputs.forEach((input, i) => {
    input.order = i + 1

    if (input.fieldType === FieldType.QUESTION) {
      questionFields.push(input)
    }

    if (input.fieldType === FieldType.TITLE) {
      titleFields.push(input)
    }
  })

  const payload = {
    acceptingResponse: true,
    externalTitle: form.title,
    internalTitle: form.title,
    open: !!form.open,
    questionEntityBlocks: questionFields.map(item => ({
      fieldKey: item.fieldKey,
      schemaKey: item.schemaKey,
      tableKey: item.tableKey,
      title: item.title,
      order: item.order,
      description: null,
      hidden: item.hidden,
      mergeWith: item.mergeWith,
      defaultValue: item.defaultValue,
      customField: item.customField,
    })),
    submitButtonColorCode: form.submitButtonColorCode?.toUpperCase(),
    submitLabelColorCode: form.submitLabelColorCode?.toUpperCase(),
    submitLabelText: form.submitLabelText,
    thankYouPageParagraph: form.thankYouPageParagraph,
    thankYouPageTitle: form.thankYouPageTitle,
    internalDescription: form.description,
    externalDescription: form.description,
    textBlocks: titleFields.map(item => ({
      title: item.title,
      order: item.order,
      description: item.description,
    })),
    bannerMimeType,
  }

  if (!payload.textBlocks.length) {
    delete payload.textBlocks
  }

  return payload
}

const filterByType = (fields, type) => fields.filter(({ fieldType }) => fieldType === type)

const formatQuestionBlocksForUpdate = (fieldInputs, lastSavedFieldInputs) => {
  const newQuestionEntityBlocks = []
  const updatedQuestionEntityBlocks = []

  const unsavedQuestionFields = filterByType(fieldInputs, FieldType.QUESTION)
  const lastSavedQuestionFieldIds = filterByType(lastSavedFieldInputs, FieldType.QUESTION)
    .map(({ fieldId }) => fieldId)

  unsavedQuestionFields.forEach(field => {
    const partOfLastSaved = lastSavedQuestionFieldIds.includes(field.fieldId)
    partOfLastSaved ? updatedQuestionEntityBlocks.push(field) : newQuestionEntityBlocks.push(field)
  })

  const deletedQuestionEntityBlocks = lastSavedQuestionFieldIds
    .filter(id => !unsavedQuestionFields.some(({ fieldId }) => id === fieldId))

  return {
    newQuestionEntityBlocks,
    updatedQuestionEntityBlocks,
    deletedQuestionEntityBlocks,
  }
}

const formatTitleBlocksForUpdate = (fieldInputs, lastSavedFieldInputs) => {
  const newTextBlocks = []
  const updatedTextBlocks = []

  const unsavedTitleFields = filterByType(fieldInputs, FieldType.TITLE)
  const lastSavedTitleFieldIds = filterByType(lastSavedFieldInputs, FieldType.TITLE)
    .map(({ fieldId }) => fieldId)

  unsavedTitleFields.forEach(field => {
    const partOfLastSaved = lastSavedTitleFieldIds.includes(field.fieldId)
    partOfLastSaved ? updatedTextBlocks.push(field) : newTextBlocks.push(field)
  })

  const deletedTextBlocks = lastSavedTitleFieldIds
    .filter(id => !unsavedTitleFields.some(({ fieldId }) => id === fieldId))

  return {
    newTextBlocks,
    updatedTextBlocks,
    deletedTextBlocks,
  }
}

export function formatStateAsUpdatePayload({
  form, fieldInputs, lastSavedFieldInputs, version, removeBannerImage, bannerMimeType,
}) {
  fieldInputs = fieldInputs.map((input, i) => ({ ...input, order: i + 1 }))

  const {
    newQuestionEntityBlocks,
    updatedQuestionEntityBlocks,
    deletedQuestionEntityBlocks,
  } = formatQuestionBlocksForUpdate(fieldInputs, lastSavedFieldInputs)

  const {
    newTextBlocks,
    updatedTextBlocks,
    deletedTextBlocks,
  } = formatTitleBlocksForUpdate(fieldInputs, lastSavedFieldInputs)

  const payload = {
    version,
    acceptingResponse: true,
    externalTitle: form.title,
    internalTitle: form.title,
    open: !!form.open,
    newQuestionEntityBlocks,
    updatedQuestionEntityBlocks,
    deletedQuestionEntityBlocks,
    newTextBlocks,
    updatedTextBlocks,
    deletedTextBlocks,
    submitButtonColorCode: form.submitButtonColorCode?.toUpperCase(),
    submitLabelColorCode: form.submitLabelColorCode?.toUpperCase(),
    submitLabelText: form.submitLabelText,
    thankYouPageParagraph: form.thankYouPageParagraph,
    thankYouPageTitle: form.thankYouPageTitle,
    internalDescription: form.description,
    externalDescription: form.description,
    bannerMimeType,
    removeBannerImage,
  }

  const deleteKeyIfEmpty = key => {
    if (!payload[key].length) {
      delete payload[key]
    }
  }

  deleteKeyIfEmpty('newQuestionEntityBlocks')
  deleteKeyIfEmpty('updatedQuestionEntityBlocks')
  deleteKeyIfEmpty('deletedQuestionEntityBlocks')
  deleteKeyIfEmpty('newTextBlocks')
  deleteKeyIfEmpty('updatedTextBlocks')
  deleteKeyIfEmpty('deletedTextBlocks')

  return payload
}

export const getLinkableFieldTypes = fieldType => {
  switch (fieldType) {
    case CustomFieldTypes.Number:
    case CustomFieldTypes.Percent:
      return [CustomFieldTypes.Number, CustomFieldTypes.Percent]

    case CustomFieldTypes.Date:
      return [CustomFieldTypes.Date]

    case CustomFieldTypes.Bool:
      return [CustomFieldTypes.Bool]

    case CustomFieldTypes.Url:
      return [CustomFieldTypes.Url]

    case CustomFieldTypes.Email:
      return [CustomFieldTypes.Email]

    case CustomFieldTypes.Phone:
      return [CustomFieldTypes.Phone]

    default:
      return []
  }
}
