<template>
  <div>
    <div class="tw-my-4 result-table-container">
      <b-table
        ref="refTable"
        style="max-height: 500px; overflow: auto;"
        :busy="loading"
        class="table-response"
        responsive
        :items="items"
        :fields="isAdvanced? advancedColumns : (forPreview ? fieldColumns : columnTypes) "
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="data">
          <div
            v-if="!forPreview"
            style="white-space: nowrap; cursor: pointer;"
            class="text-white"
          >
            <span>{{ $t(data.label) }}</span>
            <feather-icon
              v-if="!forPreview"
              :color="
                data.field.selectedFilterData.condition.label &&
                  'var(--colour--dark)'
              "
              class="tw-mx-3"
              icon="FilterIcon"
              @click.prevent="openFilterForm(data)"
            />
            <feather-icon
              v-if="!forPreview"
              :color="
                (typeof data.field.selectedAggregates === 'object' ||
                  data.field.isGroupBy) &&
                  'var(--colour--dark)'
              "
              icon="GitPullRequestIcon"
              @click.prevent="openAggregationForm(data)"
            />
          </div>
        </template>

        <template #cell(title)="{ item }">
          <span class="text-nowrap">
            {{ item.name }}
          </span>
        </template>
      </b-table>
    </div>
    <prozess-pagination
      v-if="!loading"
      class="mx-1 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @change="handlePageChange"
    />

    <WidgetAggregationForm
      ref="aggregateForm"
      :visible="showAggregationForm"
      @save="setSelectedAggregation"
    />

    <WidgetFilterForm
      ref="filterForm"
      :visible="showFilterForm"
      @save="setSelectedFilter"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import RootEvents from '@/constants/rootEvents'
import advancedSearch from '@/services/advancedSearch'
import formatDynamicColumns from '@/mixins/formatDynamicColumn'
import WidgetAggregationForm from '@/components/Widgets/WidgetAggregationForm.vue'
import WidgetFilterForm from '@/components/Widgets/WidgetFilterForm.vue'
import { filterInitialForm, aggregates } from '@/constants/widgets'
import { dashboardService } from '@/services/dashboard'
import fieldsManagementService from '@/services/advancedFieldMgmt'

export default {
  name: 'WidgetDataSourceTable',
  components: { WidgetAggregationForm, WidgetFilterForm },
  mixins: [formatDynamicColumns],
  props: {
    forPreview: {
      type: Boolean,
      default: false,
    },
    dashboardFilterValues: {
      type: Array,
      default: () => [],
    },
    widgetId: {
      type: String,
      default: '',
    },
    dashboardId: {
      type: String,
      default: '',
    },
    result: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      type: 'widget',
      loading: false,
      currentPage: 1,
      total: 0,
      perPage: 25,
      sortDesc: true,
      showAggregationForm: false,
      showFilterForm: false,
      sortBy: null,
      items: [],
      selectedAggregateIndex: null,
      selectedFilterIndex: null,
      columns: {},
      configuration: null,
      headers: [],
      columnTypes: [],
      isAdvanced: false,
      advancedColumns: [],
    }
  },

  computed: {
    ...mapState('widget', ['userFilterValues', 'id', 'widgetConfiguration']),
    dataMeta() {
      let localItemsCount = this.items.length

      if (this.refTable) {
        localItemsCount = this.refTable.localItems.length
      }

      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },

  watch: {
    dashboardId: {
      handler(newVal) {
        if (newVal) this.callApi(0)
      },
      immediate: true,
    },

    dashboardFilterValues: {
      handler(newVal) {
        if (newVal) this.callApi(0)
      },
    },
  },
  mounted() {
    this.registerBusEvent(
      RootEvents.WIDGET_INITIATE_DATASOURCE_TABLE,
      async ({ response, columnTypes }) => {
        const data = columnTypes.response.data
        if (response.data.query == null) {
          this.isAdvanced = false
          data.map(item => item.fields.forEach(field => {
            this.columnTypes.push({
              ...field,
              fieldInfo: {
                schemaKey: item.schemaKey,
                tableKey: item.tableKey,
                fieldKey: field.fieldKey,
                customField: field.customField,
              },
              sortable: false,
              selectedAggregates: this.getFieldSelectedAggregatesData(field),
              isGroupBy: this.getFieldGroupByData(field),
              selectedFilterData: this.getFieldFilterData(field) ?? this.$lodash.cloneDeep(filterInitialForm),
              key: field.customField ? `${item.schemaKey}.${item.tableKey}.${field.fieldKey}.c` : `${item.schemaKey}.${item.tableKey}.${field.fieldKey}`,
            })
          }))
          this.setColumns(this.columnTypes)
        }

        this.runDataSourceTable(response)
      },
    )

    this.registerBusEvent(RootEvents.BASIC_ASSIGN_VALUE, ({ name, value }) => {
      this[name] = value
    })

    this.registerBusEvent(RootEvents.CALL_API, async payload => {
      this.loading = true
      const { response } = !this.forPreview
        ? await this.$async(advancedSearch.runUnsavedQuery(...payload))
        : await this.$async(
          dashboardService('widget').getWidgetData(
            this.id || this.dashboardId,
            {
              userFilterValues: this.userFilterValues,
              page: payload.orderField ? payload.page : payload,
              orderField: payload.orderField ? payload.orderField : undefined,
            },
          ),
        )

      if (this.forPreview) this.setPreviewData(response.data)

      const data = !this.forPreview ? response.data : response.data.result
      this.currentPage
      this.total = data.filteredItemsCount
      this.items = data.pageItems
      this.loading = false
    })
  },

  methods: {
    ...mapMutations({
      setColumns: 'widget/setColumns',
      setPreviewData: 'widget/setPreviewData',
    }),
    getColumnsAdvanced(items) {
      const advancedColumns_ = []
      if (items.length > 0) {
        Object.keys(items[0]).forEach(k => {
          advancedColumns_.push({
            fieldInfo: {
                schemaKey: '',
                tableKey: '',
                fieldKey: '',
                customField: false,
              },
            key: k,
            type: 'TEXT',
            label: k,
            customField: false,
            sortable: false,
            isGroupBy: false,
            selectedAggregates: '',
            selectedFilterData: {
              value: '',
              condition: {
                label: '',
                value: '',
              },
            },
          })
        })
      }
      return advancedColumns_
    },
    setPreviewData(data) {
      this.previewData = data
    },
    async openFilterForm({ field }) {
      const fieldKey = field.fieldInfo
      const payload = {
        schema: fieldKey.schemaKey,
        table: fieldKey.tableKey,
        key: fieldKey.fieldKey,
      }
      const { response } = await this.$async(
        fieldsManagementService.getOne(payload),
      )
      const data = {
        field,
        form: field.selectedFilterData,
        key: field.key,
        type: field.type,
        details: response.data,
      }
      this.selectedFilterIndex = this.findIndex(field.key)
      this.$refs.filterForm.setData({ ...data })
      this.showFilterForm = true
    },
    setSelectedFilter(data) {
      this.columnTypes[this.selectedFilterIndex].selectedFilterData = data
      this.showFilterForm = false
    },
    setSelectedAggregation(selectedAggregate, isGroupBySelected) {
      this.columnTypes[
        this.selectedAggregateIndex
      ].selectedAggregates = selectedAggregate
      this.columnTypes[
        this.selectedAggregateIndex
      ].isGroupBy = isGroupBySelected
      this.showAggregationForm = false
    },

    findIndex(key) {
      return this.columnTypes.findIndex(header => header.key === key)
    },

    openAggregationForm(data) {
      const findIndex = this.findIndex(data.field.key)
      this.selectedAggregateIndex = findIndex
      this.$refs.aggregateForm.setData(data)
      this.showAggregationForm = true
    },
    async handleSortChange({ sortBy, sortDesc, currentPage }) {
      const [schemaKey, tableKey, fieldKey] = sortBy.split('.')

      const tableSorting = [
        {
          field: {
            schemaKey,
            tableKey,
            fieldKey,
            customField: true,
          },
          descending: sortDesc,
        },
      ]

      const payload = {
        page: currentPage,
        size: this.perPage,
        tableSorting,
        sortDesc,
      }

      this.dashboardId
        ? this.callApi(payload, 'handleSortChange')
        : this.$root.$emit(RootEvents.CALL_API, payload)
    },
    async handlePageChange(page) {
      this.currentPage = page
      const payload = this.forPreview
        ? page - 1
        : [this.configuration, { page: page - 1, size: this.perPage }]

      this.dashboardId
        ? this.callApi(payload)
        : this.$root.$emit(RootEvents.CALL_API, payload)
    },
    async runDataSourceTable(datasource) {
      let payload = {}
      if (datasource.data.query == null) {
        this.configuration = datasource.data.configuration
        payload = [this.configuration, { page: 0, size: this.perPage }]
      } else {
        this.isAdvanced = true
        payload = [{ query: datasource.data.query }, { page: 0, size: this.perPage }]
      }
      this.dashboardId
        ? this.callApi(payload)
        : this.$root.$emit(RootEvents.CALL_API, payload)
    },

    async callApi(payload, src) {
      this.loading = true

      let userFilterValues = null
      let config = {}
      if (this.dashboardFilterValues !== null && this.dashboardFilterValues.length > 0) {
        userFilterValues = this.dashboardFilterValues.map(_filter => ({
          field: _filter.field,
          value: _filter.paramValue,
        }))
        config = {
            userFilterValues,
            page: payload.tableSorting?.length ? payload.page : payload,
            tableSorting: payload.tableSorting?.length
              ? payload.tableSorting
              : undefined,
          }
      } else {
        this.isAdvanced = true
      }
      if ((this.$route.name === 'company-view' || this.$route.name === 'contact-view')
        && this.$route.params.tab === '360-overview') {
        this.total = this.result.filteredItemsCount
        this.items = this.result.pageItems
        this.loading = false
      } else if (this.$route.name === 'company-view' || this.$route.name === 'contact-view') {
        const resulta = await dashboardService('widget').getDashboardWidgetSpecificData(
          this.dashboardId,
          this.widgetId,
          this.$route.name === 'company-view' ? 'company' : 'contact',
          this.$route.params.id,
        )
        const data = resulta.data.result
        this.total = data.filteredItemsCount
        this.items = data.pageItems
        this.loading = false
      } else {
        const { response } = await this.$async(
          dashboardService('widget').getDashboardWidgetData(
            this.$route.name === 'dashboard' ? this.$route.params.id : this.dashboardId,
            this.$route.name !== 'dashboard' ? this.widgetId : this.dashboardId,
            config,
          ),
        )
        const data = response.data.result
        this.total = data.filteredItemsCount
        this.items = data.pageItems
        this.advancedColumns = this.getColumnsAdvanced(data.pageItems)
        this.loading = false
      }
    },

    getFieldFilterData(field) {
      if (this?.widgetConfiguration?.widgetConfiguration?.filters?.length == null || this.widgetConfiguration.widgetConfiguration.filters.length === 0) {
        return null
      }
      const fieldWidgetFilterConfigurationIndex = this.widgetConfiguration.widgetConfiguration.filters.findIndex(element => element.field.fieldKey === field.fieldKey)
      if (fieldWidgetFilterConfigurationIndex < 0) {
        return null
      }
      const fieldWidgetFilterConfiguration = this.widgetConfiguration.widgetConfiguration.filters[fieldWidgetFilterConfigurationIndex]
      return {
        value: fieldWidgetFilterConfiguration.paramValue ?? '',
        condition: {
          label: fieldWidgetFilterConfiguration.operation ?? '',
          value: fieldWidgetFilterConfiguration.operation ?? '',
        },
        userAccessible: fieldWidgetFilterConfiguration.isParamUserAccessible ?? false,
        selectedUser: {
          label: '',
        },
      }
    },
    getFieldGroupByData(field) {
      if (this?.widgetConfiguration?.widgetConfiguration?.groupBy?.length == null || this.widgetConfiguration.widgetConfiguration.groupBy.length === 0
          || this.widgetConfiguration.widgetConfiguration.groupBy.findIndex(e => e.field.fieldKey === field.fieldKey) < 0) {
        return false
      }
      return true
    },
    getFieldSelectedAggregatesData(field) {
      let aggregateIndex = -1
      if (this?.widgetConfiguration?.widgetConfiguration?.countLabelConfiguration?.countConfigurations?.length === undefined
          || this.widgetConfiguration.widgetConfiguration.countLabelConfiguration.countConfigurations.length === 0) {
        if (this?.widgetConfiguration?.widgetConfiguration?.singleNumberConfiguration?.field?.fieldKey !== field.fieldKey) {
          return ''
        }
        aggregateIndex = aggregates.findIndex(e => e.key === this?.widgetConfiguration?.widgetConfiguration?.singleNumberConfiguration?.aggregationFunction)
      } else {
        const groupByFieldIndex = this.widgetConfiguration.widgetConfiguration.countLabelConfiguration.countConfigurations.findIndex(e => e.countField.fieldKey === field.fieldKey)
        if (groupByFieldIndex < 0) {
          return ''
        }
        aggregateIndex = aggregates.findIndex(e => e.key === this.widgetConfiguration.widgetConfiguration
          .countLabelConfiguration.countConfigurations[groupByFieldIndex].countAggregationFunction)
      }
      if (aggregateIndex < 0) {
        return ''
      }
      return aggregates[aggregateIndex]
    },
  },
}
</script>

<style lang="scss"></style>
