import * as yup from 'yup'

export const relationshipSchema = () =>
  yup.object().shape({
    targetEntity: yup.object().nullable(),
    thisEntityHas: yup.string().nullable(),
    targetEntityHas: yup.string().nullable(),
    parentRelationshipName: yup.string().nullable().max(50, 'Max 50 limit'),
    childRelationshipName: yup.string().nullable().max(50, 'Max 50 limit'),
  })
