import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/custom_schema/table'
// /api/custom_schema/table/{tableName}/history
export const customEntityDetailsService = tableName => ({
  ...apiHelper(`${API_URL}/${tableName}`),
})

export const customEntityRowsService = tableName => ({
  ...apiHelper(`${API_URL}/${tableName}/row`),
  getConnectedEntityRecords(id, query = {}) {
    return API.get(`${API_URL}/${tableName}/row/${id}/relationship?${toQuery(query)}`)
  },

  autocomplete({ schema, relatedTable, payload }) {
    return API.get(
      `${API_URL}/${tableName}/autocomplete_for_related_table?${toQuery({
        mainTable: tableName,
        relatedTableSchema: schema,
        relatedTableName: relatedTable,
        query: payload,
      })}`,
    )
  },

  getHistory(table, id, query = { size: 25 }) {
    return API.get(`${API_URL}/${table}/row/${id}/history?${toQuery(query)}`)
  },
})
