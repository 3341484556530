<template>
  <div>
    <sidebar-form
      :visible="showForm"
      :resource-id="resourceId"
      :entity-id="entityId"
      :entity-type="entityType"
      :schema-name="schemaName"
      @saved="fetchList()"
      @close="onCloseForm"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%;"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <div class="mb-2">
            <button
              id="create-note-button"
              class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
              :class="{
                'icon-disabled': canUpdate === false,
              }"
              @click="canUpdate && create()"
            >
              <feather-icon
                icon="UserPlusIcon"
                class="mr-50"
              />
              <span>{{ $t('Add Note') }}</span>
            </button>
            <b-tooltip target="create-note-button">
              {{
                canUpdate === true
                  ? $t('Add Note')
                  : $t('You dont have permission to add note')
              }}
            </b-tooltip>
          </div>
          <div
            v-for="item of items"
            :key="item.vueKey"
            class="prozess-company-contact__item"
          >
            <div
              class="prozess-company-contact__summary d-flex justify-content-center align-items-center"
            >
              <div
                class="prozess-company-contact__item-name"
                :style="{ flex: 0.66 }"
              >
                <prozess-resource-name
                  :has-permission="$can('Read', 'Note')"
                  :to="{ name: 'note-view', params: { id: item.uuid, tab: GenericTabs.OVERVIEW_360 } }"
                  :title="item.title"
                />
              </div>
              <div
                class="prozess-company-contact__item-description line-clamp-1 pr-3"
              >
                {{ markupToText(item.notes) }}
              </div>
              <div
                class="prozess-company-contact__item-right-buttons d-flex align-items-center"
              >
                <div
                  class="prozess-company-contact__item-action-buttons d-flex"
                >
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`popup-row-${item.uuid}-preview-icon`"
                      icon="EyeIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      @click="handlePreview(item)"
                    />
                    <b-tooltip
                      :title="$t('Preview')"
                      :target="`popup-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                    <feather-icon
                      :id="`edit-row-${item.uuid}-preview-icon`"
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{
                        'icon-disabled': canUpdate === false,
                      }"
                      @click="canUpdate ? edit(item) : null"
                    />
                    <b-tooltip
                      :title="
                        canUpdate === true
                          ? $t('Edit')
                          : $t('You dont have permission to update note')
                      "
                      :target="`edit-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                    <feather-icon
                      :id="`delete-row-${item.uuid}-preview-icon`"
                      icon="TrashIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{
                        'icon-disabled': canUpdate === false,
                      }"
                      @click="confirmRemoval(item)"
                    />
                    <b-tooltip
                      :title="
                        canUpdate === true
                          ? $t('Remove')
                          : $t('You don\'t have permission to detach note')
                      "
                      :target="`delete-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                  </div>
                </div>
                <div class="prozess-company-contact__item-show-more ml-2">
                  <div @click="showMore(item)">
                    {{ $t('Show More') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <prozess-pagination
            v-if="!loading"
            class="mx-2 mb-2 mt-2"
            :class="{ 'd-none': !total }"
            :meta="dataMeta"
            :page="currentPage"
            :total="total"
            :size="perPage"
            @change="handleCurrentPage"
          />
        </div>
      </div>
    </b-overlay>
    <multi-document-preview-modal />
    <note-view-edit-modal
      @saved="fetchList()"
      @close="onCloseForm"
    />
  </div>
</template>

<script>
import { markupToText } from '@/helpers/app'
import { GenericTabs, Schema } from '@/constants/app'
import SidebarForm from '@/views/pages/Notes/Form.vue'
import noteService from '@/services/note'
import listMixinFactory from '@/mixins/list'
import detachOrDeleteMixinFactory from '@/mixins/detachOrDeleteMixin'
import MultiDocumentPreviewModal from '@/views/shared/MultiDocumentPreviewModal.vue'
import NoteViewEditModal from '@/views/pages/Notes/NoteViewEditModal.vue'

export default {
  components: {
    SidebarForm,
    MultiDocumentPreviewModal,
    NoteViewEditModal,
  },

  mixins: [
    listMixinFactory({
      autoRouteName: true,
      service: noteService,
      listFormat: true,
      disableAutoFetchlist: true,
    }),
    detachOrDeleteMixinFactory({
      uuidKey: 'noteUuid',
      versionKey: 'noteVersion',
    }),
  ],

  props: {
    entityType: {
      type: String,
      required: true,
    },

    entityId: {
      type: String,
      required: true,
    },

    schemaName: {
      type: String,
      default: 'crm',
    },
  },

  data() {
    return {
      GenericTabs,
      loading: false,
      showForm: false,
      resourceId: null,

      routeParams: {
        tab: 'notes',
      },
    }
  },

  computed: {
    canUpdate() {
      if (this.schemaName === Schema.CUSTOM) {
        return this.$can('Update', this.entityType)
      }

      switch (this.entityType) {
        case 'inquiry':
          return this.$can('Update', 'Inquiry')

        default:
          return this.$can('Update', 'CRM')
      }
    },

    detachOrDelete() {
      return noteService.detachOrDelete
    },

    canDetachOrDelete() {
      return this.canUpdate
    },

    handleDeletionCompletion() {
      return this.fetchList
    },
  },

  methods: {
    async checkIfDetachOnly(note) {
      const { response } = await this.$async(
        noteService.getOneByEntity(
          this.schemaName,
          this.entityType,
          this.entityId,
          note.uuid,
        ),
      )

      return response.data.connectedEntities.length > 1
    },

    create() {
      this.showForm = !this.showForm
    },

    edit(item) {
      this.resourceId = item.uuid
      this.showForm = true
    },

    async getAllMethod(query) {
      const { response } = await this.$async(noteService.getCustomFields())

      this.customFields = this.$customFields.sort(response.data)

      return noteService.getAttached(
        this.schemaName,
        this.entityType,
        this.entityId,
        query,
      )
    },

    async handlePreview(item) {
      const documents = await this.prepareDocuments(item.uuid)
      if (documents.length) {
        this.$root.$emit('prozess-document-preview-modal-toggled', {
          open: true,
          documents,
        })
      }
    },

    markupToText,

    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },

    async prepareDocuments(id) {
      this.$swal({ title: this.$t('Verifying...') })
      this.$swal.showLoading()

      const { response } = await this.$async(
        noteService.getAllDocumentsByEntity(
          this.schemaName,
          this.entityType,
          this.entityId,
          id,
        ),
      )

      if (!response.data.pageItems.length) {
        this.$swal.close()

        this.$nextTick(() => {
          this.$swal({
            title: this.$t(
              'This item does not have any linked documents to show.',
            ),
            icon: 'info',
            confirmButtonText: this.$t('Close'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
            allowOutsideClick: false,
          })
        })

        return []
      }

      const { response: documentResponses } = await this.$async(
        Promise.all(
          response.data.pageItems.map(({ uuid }) => noteService.getOneDocumentsByEntity(
            this.schemaName,
            this.entityType,
            this.entityId,
            id,
            uuid,
          )),
        ),
      )

      const documents = documentResponses.map(({ data }) => ({
        name: data.name,
        uuid: data.uuid,
        mimeType: data.directUrlLatestVersion.mimeType,
        url: data.directUrlLatestVersion.url,
      }))

      this.$swal.close()

      return documents
    },

    routeWatcher(params) {
      if (params.tab === 'notes') {
        this.fetchList()
      }
    },

    showMore(note, mode = 'view') {
      this.$root.$emit('note-edit-view-modal-toggled', {
        open: true,
        schemaName: this.schemaName,
        entityType: this.entityType,
        entityId: this.entityId,
        note,
        mode,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/company-contact-list.scss';
</style>
