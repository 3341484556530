<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    form-icon="PlusIcon"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    :saving-disabled="isCustomFieldsEmpty"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <div v-if="customFields.length && loading == false">
        <prozess-custom-fields
          v-model="resource.customFields"
          :custom-fields="customFields"
          :errors="errors"
          @enter="save"
        />
      </div>
      <div>
        <relationships
          v-model="connections"
          :relationships="relationships"
          :related-entity-type="relatedEntityType"
          :errors="errors"
        />
      </div>

      <form-notification
        v-if="resourceId && !owningEntityId"
        :id="resourceId"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { customEntityDataSchema } from '@/schema/customEntityData'
import { preventSpecialChars } from '@/helpers/app'
import { Schema } from '@/constants/app'
import { fillableOnly } from '@/helpers/field'
import FormNotification from '@/components/Forms/FormNotification.vue'
import ProzessCustomFields from '@/@core/components/ProzessCustomFields.vue'
import Relationships from './Relationships.vue'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import { relationshipSchema } from '@/schema/relationship'
import moment from 'moment'

const defaultCustomEntity = {
  customFields: {},
}

export default {
  name: 'FormEntity',
  components: {
    ProzessCustomFields,
    Relationships,
    FormNotification,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    service: {
      type: Object,
      default: null,
    },

    relationships: {
      type: Array,
      default: () => [],
    },

    owningEntityId: {
      type: [Number, String],
      default: null,
    },

    owningEntityType: {
      type: String,
      default: null,
    },

    owningSchema: {
      type: String,
      default: null,
    },

    relatedEntityType: {
      type: String,
      default: null,
    },

    formId: {
      type: String,
      default: 'customEntityForm',
    },
  },
  data() {
    return {
      resource: defaultCustomEntity,
      options: [],
      saving: false,
      loading: true,
      errors: [],
      keyFieldTouched: false,
      customFields: [],
      connections: {},
      originalConnections: {},
      removeConnections: [],
      addConnections: [],
    }
  },
  computed: {
    otherEntitiesWithOneRelationship() {
      const tabParams = this.$route.params.tab
      const currentEntityKey = this.$route.params.key || this.relatedEntityType

      return this.relationships
        .map(item => {
          const relationshipTypes = {
            1: 'ONE',
            N: 'MANY',
            M: 'MANY',
          }
          const getOtherEntity = number => ({
            label: item[`label${number}`],
            key: item[`key${number}`],
            schema: item[`schema${number}`],
            id: item.id,
            builtIn: item.builtIn,
            type: item.type,
            relationshipType: relationshipTypes[item.type.split('to')[number - 1]],
          })

          return item.key1 === currentEntityKey || item.key1 === tabParams ? getOtherEntity(2) : getOtherEntity(1)
        })
        .filter(
          item =>
            item.relationshipType === 'ONE' && !item.builtIn && !this.$route.name.includes(item.key) && tabParams !== item.key && currentEntityKey !== item.key,
        )
    },
    formTitle() {
      return this.resourceId ? 'Edit Record' : 'Add New Record'
    },
    isDocuments() {
      return this.$route.params.tab === 'documents'
    },
    isRouteTabAvailable() {
      return this.$route.params.tab ? this.$route.params.tab : this.$route.params.key
    },
    rowValues() {
      const customFields = this.$lodash.cloneDeep(this.resource.customFields)
      return Object.keys(customFields).reduce((acc, key) => {
        if (!key.includes('___')) {
          acc[key] = customFields[key]
        }
        return acc
      }, {})
    },
    isCustomFieldsEmpty() {
      return Object.keys(this.rowValues).length === 0
    },
  },
  watch: {
    'resource.label': function (value) {
      if (this.resourceId) return

      if (this.keyFieldTouched === false) {
        this.resource.key = this.$case.snake(value)
      }
    },
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()

          this.getFields()

          if (this.resourceId) this.getOne()
        }
      },
      immediate: true,
    },
  },
  methods: {
    preventSpecialChars(e) {
      return preventSpecialChars(e, { regExp: '^[a-z0-9_]+$' })
    },
    handleKeyInput() {
      this.keyFieldTouched = true
    },
    reset() {
      this.customFields = []
      this.errors = []
      this.connections = {}
      this.resource = this.$lodash.cloneDeep(defaultCustomEntity)
      this.loading = false
      this.saving = false
      this.keyFieldTouched = false
    },

    async getFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schemaKey: Schema.CUSTOM,
          tableKey: this.relatedEntityType ? this.relatedEntityType : this.$route.params.key,
        }),
      )
      const fieldMetadata = fillableOnly(response.data)

      this.customFields = fieldMetadata.map(item => ({
        ...item,
        fieldType: item.type,
      }))
    },

    async getOne() {
      this.loading = true
      const request = this.owningEntityType
        ? this.service.getOneRelatedEntityData({
            ...this.$lodash.pick(this, ['owningSchema', 'owningEntityType', 'owningEntityId', 'relatedEntityType']),
            relatedSchema: 'custom_schema',
            relatedEntityId: this.resourceId,
          })
        : this.service.getOne(this.resourceId)

      const { response } = await this.$async(request)
      const { values, connections } = response.data
      this.resource = {
        customFields: values,
        version: values.version,
      }
      this.connections = connections.reduce((acc, item) => {
        if (item.rows.length) {
          const [data] = item.rows
          if (!data) return
          acc[item.tableKey] = {
            isNew: false,
            label: data.rowName,
            value: {
              relationshipId: data.id,
              schema: data.schemaName,
              tableName: data.tableKey,
              uuidKey: data.uuidKey,
            },
          }
        }
        return acc
      }, {})
      this.originalConnections = this.$lodash.cloneDeep(this.connections)
      this.loading = false
    },
    close() {
      this.$emit('close')
    },
    prepareConnectionData(connections) {
      return Object.keys(connections).reduce((acc, key) => {
        if (connections[key]) {
          acc.push(connections[key].value)
        }
        return acc
      }, [])
    },
    compareConnections() {
      const connections = this.prepareConnectionData(this.connections)
      const originalConnections = this.prepareConnectionData(this.originalConnections)

      this.removeConnections = originalConnections.filter(pendingRemoveItem => {
        const index = connections.findIndex(item => item.uuidKey === pendingRemoveItem.uuidKey)
        return index === -1 // include in "remove list" if no longer exist
      })

      this.addConnections = connections.filter(connection => {
        const originalConnection = originalConnections.find(item => item.tableName === connection.tableName)
        if (!originalConnection) return true // add if it doesnt exist yet
        return originalConnection.uuidKey !== connection.uuidKey // add if no longer the same data
      })
    },
    async save() {
      this.errors = await this.yupValidate(customEntityDataSchema(this.customFields), this.resource, relationshipSchema(this.relationships))

      if (this.otherEntitiesWithOneRelationship.length > 0) {
        const connections = this.prepareConnectionData(this.connections)

        if (connections?.length < this.otherEntitiesWithOneRelationship.length) {
          const filtered = this.otherEntitiesWithOneRelationship.filter(item => !connections.find(connection => connection.tableName === item.key))

          if (filtered.length > 0) {
            for (const item of filtered) {
              this.errors.push({
                field: item.key,
                defaultMessage: 'Required',
              })
            }
          }
        }
      }

      if (this.errors.length > 0) return

      let resource = {
        [this.resourceId ? 'values' : 'rowValues']: this.rowValues,
      }
      if (this.resourceId) {
        this.compareConnections()
        resource = {
          ...resource,
          addConnections: this.addConnections,
          removeConnections: this.removeConnections,
        }
        resource.version = this.resource.version
        delete resource.values.version
        delete resource.values.uuid_key
      } else {
        resource.connections = this.prepareConnectionData(this.connections)
      }
        Object.values(this.customFields).filter(cf => cf.dateAutoset && cf.customField).forEach(cc => {
          const dateNow = new Date()
          if (cc.type === 'DATE') {
            resource.rowValues[cc.key] = moment(dateNow).format('YYYY-MM-DD')
          } else if (cc.type === 'DATETIME') {
            resource.rowValues[cc.key] = moment(dateNow).format('YYYY-MM-DDTHH:mmZ')
          }
        })

      this.saving = true
      const { error } = await this.$async(this.createOrUpdate(resource))
      if (error) {
        this.errors = this.$lodash.get(error, 'response.data.errors', [])
      } else {
        this.$emit('saved')
        this.close()
      }
      this.saving = false
    },
    createOrUpdate(data) {
      if (this.owningEntityType) {
        return this.resourceId
          ? this.service.updateRelatedEntityData(
              {
                ...this.$lodash.pick(this, ['owningSchema', 'owningEntityType', 'owningEntityId', 'relatedEntityType']),
                relatedSchema: 'custom_schema',
                relatedEntityId: this.resourceId,
              },
              data,
            )
          : this.service.createRelatedEntityData(
              {
                ...this.$lodash.pick(this, ['owningSchema', 'owningEntityType', 'owningEntityId', 'relatedEntityType']),
                relatedSchema: 'custom_schema',
              },
              data,
            )
      }

      return this.resourceId ? this.service.patch(this.resourceId, data) : this.service.store(data)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
