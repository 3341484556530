export default {
  methods: {
    breakDownColumnKey(key) {
      // Key Format: `<schema>.<table>.<column>:<customFieldIndicator>`
      const [schemaName, tableKey, columnKey] = key.split('.')
      const [column, customFieldIndicator] = columnKey.split(':')
      return {
        label: this.$case.title(this.$t(column)),
        key,
        column,
        schemaName,
        tableKey,
        customField: !!customFieldIndicator,
      }
    },
  },

  computed: {
    fieldColumns() {
      const [basis] = this.result?.pageItems ?? this.items
      return basis
        ? Object.keys(basis).map(key => {
          const { label } = this.breakDownColumnKey(key)
          return {
            key,
            label,
            sortable: this.forPreview,
          }
        })
        : []
    },
  },
}
