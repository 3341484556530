import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const getSecondary = {
  company: 'contact',
  contact: 'company',
}

export default (id, primary = 'company') => {
  const getApiUrl = _id => `/api/crm/${primary}/${_id}/${getSecondary[primary]}`
  return {
    ...apiHelper(getApiUrl(id)),
    getAllByCompanyID: (companyId, query) => API.get(`/api/crm/company/${companyId}/contact?&${toQuery(query)}`),
    getOneByCompany: (companyId, relationshipid, query) => API.get(`/api/crm/company/${companyId}/contact/${relationshipid}?&${toQuery(query)}`),
    getCustomFields() {
      return API.get('/api/crm/company_contact/custom_fields')
    },
    getAdvanceFields() {
      return API.get('/api/advance_field/crm/company_has_contact')
    },
    removeRelation(resourceId, relationshipId) {
      return API.delete(`/api/crm/company/${resourceId}/contact/${relationshipId}`)
    },
    updateRelation(resourceId, relationshipId, payload) {
      return API.put(`/api/crm/company/${resourceId}/contact/${relationshipId}`, payload)
    },
    getRelatedUsers: (schemaName, tableName, tableUuid) => API.get(`/api/iam/users/company_contact/${schemaName}/${tableName}/${tableUuid}`),
  }
}
