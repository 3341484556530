<template>
  <div
    v-show="otherEntitiesWithOneRelationship.length"
    class="prozess-relationships"
  >
    <h4 class="mb-2">
      {{ $t('Relationships') }}
    </h4>
    <div
      v-for="entity of otherEntitiesWithOneRelationship"
      :key="entity.id"
      class="mb-1"
    >
      <custom-entity-search
        :id="entity.id"
        v-model="resource[entity.key]"
        :original-entity-key="relatedEntityType || $route.params.key"
        :is-main-entity="!!relatedEntityType"
        :entity="entity"
        :label="entity.label"
        :hint="'*' + $t('Required')"
        :error="$hasError(entity.key)"
      />
    </div>
  </div>
</template>

<script>
import CustomEntitySearch from './CustomEntitySearch.vue'

export default {
  name: 'Relationships',
  components: {
    CustomEntitySearch,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    relationships: {
      type: Array,
      default: () => [],
    },
    relatedEntityType: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resource: {},
    }
  },
  computed: {
    otherEntitiesWithOneRelationship() {
      const tabParams = this.$route.params.tab
      const currentEntityKey = this.$route.params.key || this.relatedEntityType

      return this.relationships
        .map(item => {
          const relationshipTypes = {
            1: 'ONE',
            N: 'MANY',
            M: 'MANY',
          }
          const getOtherEntity = number => ({
            label: item[`label${number}`],
            key: item[`key${number}`],
            schema: item[`schema${number}`],
            id: item.id,
            builtIn: item.builtIn,
            type: item.type,
            relationshipType: relationshipTypes[item.type.split('to')[number - 1]],
          })

          return item.key1 === currentEntityKey || item.key1 === tabParams ? getOtherEntity(2) : getOtherEntity(1)
        })
        .filter(
          item =>
            item.relationshipType === 'ONE' && !item.builtIn && !this.$route.name.includes(item.key) && tabParams !== item.key && currentEntityKey !== item.key,
        )
    },
  },
  watch: {
    value: {
      handler(value) {
        this.resource = value
      },
      immediate: true,
    },
    resource: {
      handler(value) {
        this.$emit('input', value)
      },
      deep: true,
    },
  },
}
</script>
