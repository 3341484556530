const routeMap = {
    '/crm/company': '/companies',
    '/crm/contact': '/contacts',
    '/notes/note': '/notes',
    '/core/inquiry_company_contact': '/inquiries',
    '/core/todo_company_contact': '/todo',
}

export function getNotificationRoute(r) {
    return routeMap[r] ?? r
}
