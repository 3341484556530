<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="contactForm"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <FormFullEditLink v-if="!!resourceId" :resource-link="resourceLink" />
      <prozess-field-wrapper
        v-if="!resourceId && (contact && contact.isNew) "
        :icon="MetastatusTypeIcons['status']"
        :error="$hasError('statusId')"
      >
        <prozess-select
          v-model="form.statusId"
          style="flex: 1"
          :placeholder="$t('Status') "
          :options="statusOptions"
          :reduce="(option) => option.metaStatusId"
          label="metaStatusName"
          name="statusId"
          field="statusId"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <label v-if="contact">
        {{ $t('First Name') }}
      </label>
      <ContactSearch
        v-model="contact"
        :disabled="disableContactSearch"
        :error="$hasError('first_name')"
        class="mb-2"
      />
      <div v-for="field in fieldMetadata" :key="`field__${field.key}`">
        <AppDynamicField
          v-if="!fieldHidden(field)"
          v-model="form[field.key]"
          :field="field"
          :disabled="fieldDisabled(field)"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <template v-if="pivotFieldMetadata.length">
        <h4 class="mb-2">
          {{ $t('Company-Contact Custom Fields') }}
        </h4>
        <div
          v-for="field in pivotFieldMetadata"
          :key="`company-contact__${field.key}`"
        >
          <AppDynamicField
            v-if="field.type !== 'MAPPING'"
            v-model="pivotForm[field.key]"
            :field="field"
            :error="$hasError(field.key, null, pivotErrors)"
            :resourceId="resourceId"
          />
        </div>
      </template>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>
<script>
import { renameKeys } from 'lodash-contrib'
import { Entity, Schema } from '@/constants/app'
import { fillableOnly } from '@/helpers/field'
import {
  companyContactRelSchema,
  companyContactSchema,
} from '@/schema/contact'
import { formatAsPayload, standardKeyMapping } from '@/helpers/contactForm'
import { advancedFieldValue } from '@/helpers/customFields'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import companyContactService from '@/services/companyContact'
import ContactSearch from '@/views/shared/ContactSearch.vue'
import FormFullEditLink from '@/components/shared/FormFullEditLink.vue'
import { MetastatusTypeIcons } from '@/constants/metastatusTypes'

export default {
  components: {
    AppDynamicField,
    ContactSearch,
    FormFullEditLink,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      MetastatusTypeIcons,
      contact: null,
      errors: [],
      pivotErrors: [],
      fieldMetadata: [],
      form: {},
      pivotForm: {},
      loading: true,
      pivotCustomFields: {},
      pivotFieldMetadata: [],
      saving: false,
    }
  },

  computed: {
    formTitle() {
      return this.resourceId
        ? 'Edit Related Contact'
        : 'Add Related Contact'
    },

    disableContactSearch() {
      return !!this.resourceId && !!this.contact
    },

    contactExists() {
      return this.contact && !this.contact.isNew
    },

    resourceLink() {
      return this.$router.resolve({
        name: 'contact-view',
        params: { id: this.contact?.value?.uuid, tab: 'companies' },
      }).href
    },

    service() {
      return companyContactService(this.$route.params.id)
    },
  },
  watch: {
    contact(contact) {
      this.form = {
        ...this.form,
        first_name: null,
        last_name: null,
        email_address: null,
      }

      if (!contact) {
        return
      }

      if (!contact.isNew) {
        this.form.first_name = contact.value.firstName
        this.form.last_name = contact.value.lastName
        this.form.email_address = contact.value.email
      }
    },

    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getInitialData()
        }
      },

      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },

    fieldDisabled(field) {
      switch (field.key) {
        case 'last_name':
          return this.contactExists

        default:
          return false
      }
    },

    fieldHidden(field) {
      if (field.key === 'first_name') {
        return true
      }

      if (this.contactExists) {
        return field.key !== 'last_name'
      }

      return false
    },

    formatFormAsPayload() {
      const payload = {
        customFields: this.pivotForm,
        role: this.pivotForm.role,
      }

      if (this.contact?.value?.uuid) {
        payload.existingContactUuid = this.contact?.value?.uuid
      } else {
        payload.newContact = {
          ...formatAsPayload(this.form, this.fieldMetadata),
          firstName: this.contact?.value,
          statusId: this.form.statusId,

        }
      }

      payload.customFields = this.pivotForm

      return payload
    },

    async getAllFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: Schema.CRM,
          table: Entity.CONTACT,
        }),
      )

      const fieldMetadata = fillableOnly(response.data)

      // Force $set all fields to `form` object to make them reactive for v-models
      fieldMetadata.forEach(field => {
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      this.fieldMetadata = this.$lodash.cloneDeep(fieldMetadata)
      this.setStatus()
    },
    setStatus() {
      if (this.resourceId) return
      const statusDefaultValue = this.statusOptions.find(status => status.defaultTo.includes('contact'))
      this.form.statusId = statusDefaultValue?.metaStatusId ?? ''
    },
    async getCompanyContactCustomFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: Schema.CRM,
          table: Entity.COMPANY_HAS_CONTACT,
        }),
      )

      this.pivotFieldMetadata = fillableOnly(response.data).reduce(
        (acc, item) => {
          if (item.key === 'role') {
            item.required = true
          }

          acc.push(item)

          // Force $set all fields to `pivotForm` object to make them reactive for v-models
          this.$set(this.pivotForm, item.key, null)

          return acc
        },
        [],
      )
    },

    async getInitialData() {
      this.loading = true

      await Promise.all([
        this.getCompanyContactCustomFields(),
        this.getAllFields(),
      ])

      if (this.resourceId) {
        await this.getResource()
      }

      this.loading = false
    },

    async getResource() {
      const { response } = await this.$async(
        this.service.getOne(this.resourceId),
      )
      const { data: resource } = response
      const { contact } = resource

      this.contact = {
        isNew: false,
        label: contact.firstName,
        selectedLabel: contact.firstName,
        value: contact,
      }

      this.formatAsFormData(resource)
    },

    formatAsFormData({ customFieldData, contact, role }) {
      this.pivotForm = customFieldData.reduce((acc, item) => {
        acc[item.key] = advancedFieldValue(item.value, {
          toArrayIfMulti: true,
        })
        return acc
      }, {})

      this.pivotForm.role = role

      const form = this.$lodash.pick(contact, Object.keys(standardKeyMapping))

      this.form = renameKeys(form, standardKeyMapping)
    },

    reset() {
      this.contact = null
      this.errors = []
      this.form = {}
      this.loading = false
      this.saving = false
    },

    async save() {
      const fieldMetadata = this.fieldMetadata.filter(
        item => !this.fieldHidden(item),
      )

      const form = this.$lodash.cloneDeep(this.form)

      if (form.mobile_number?.startsWith('+47')) {
        form.mobile_number = form.mobile_number.substring(3).trim()
      }

      this.errors = await this.yupValidate(
        companyContactSchema(fieldMetadata),
        form,
      )

      this.pivotErrors = await this.yupValidate(
        companyContactRelSchema(this.pivotFieldMetadata),
        this.pivotForm,
      )

      if (!!this.errors.length || !!this.pivotErrors.length) {
        return false
      }

      this.saving = true

      const payload = this.formatFormAsPayload()

      const request = this.resourceId
        ? this.service.updateRelation(
          this.$route.params.id,
          this.resourceId,
          payload,
        )
        : this.service.store(payload)

      const { error } = await this.$async(request)

      this.saving = false

      if (!error) {
        this.$emit('saved')
        this.close()
      }
    },
  },
}
</script>
