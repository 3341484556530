var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sidebar-form',{attrs:{"visible":_vm.showForm,"resource-id":_vm.resourceId,"entity-id":_vm.entityId,"entity-type":_vm.entityType,"schema-name":_vm.schemaName},on:{"saved":function($event){return _vm.fetchList()},"close":_vm.onCloseForm}}),_c('b-overlay',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"show":_vm.loading,"spinner-variant":"primary","rounded":"sm","variant":"transparent"}},[_c('div',{staticClass:"card resource-view__scrollable"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"btn btn-primary d-flex justify-content-around align-items-center btn-secondary",class:{
              'icon-disabled': _vm.canUpdate === false,
            },attrs:{"id":"create-note-button"},on:{"click":function($event){_vm.canUpdate && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserPlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Add Note')))])],1),_c('b-tooltip',{attrs:{"target":"create-note-button"}},[_vm._v(" "+_vm._s(_vm.canUpdate === true ? _vm.$t('Add Note') : _vm.$t('You dont have permission to add note'))+" ")])],1),_vm._l((_vm.items),function(item){return _c('div',{key:item.vueKey,staticClass:"prozess-company-contact__item"},[_c('div',{staticClass:"prozess-company-contact__summary d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"prozess-company-contact__item-name",style:({ flex: 0.66 })},[_c('prozess-resource-name',{attrs:{"has-permission":_vm.$can('Read', 'Note'),"to":{ name: 'note-view', params: { id: item.uuid, tab: _vm.GenericTabs.OVERVIEW_360 } },"title":item.title}})],1),_c('div',{staticClass:"prozess-company-contact__item-description line-clamp-1 pr-3"},[_vm._v(" "+_vm._s(_vm.markupToText(item.notes))+" ")]),_c('div',{staticClass:"prozess-company-contact__item-right-buttons d-flex align-items-center"},[_c('div',{staticClass:"prozess-company-contact__item-action-buttons d-flex"},[_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",attrs:{"id":`popup-row-${item.uuid}-preview-icon`,"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.handlePreview(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.$t('Preview'),"target":`popup-row-${item.uuid}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                      'icon-disabled': _vm.canUpdate === false,
                    },attrs:{"id":`edit-row-${item.uuid}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.canUpdate ? _vm.edit(item) : null}}}),_c('b-tooltip',{attrs:{"title":_vm.canUpdate === true
                        ? _vm.$t('Edit')
                        : _vm.$t('You dont have permission to update note'),"target":`edit-row-${item.uuid}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                      'icon-disabled': _vm.canUpdate === false,
                    },attrs:{"id":`delete-row-${item.uuid}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.confirmRemoval(item)}}}),_c('b-tooltip',{attrs:{"title":_vm.canUpdate === true
                        ? _vm.$t('Remove')
                        : _vm.$t('You don\'t have permission to detach note'),"target":`delete-row-${item.uuid}-preview-icon`,"placement":"left"}})],1)]),_c('div',{staticClass:"prozess-company-contact__item-show-more ml-2"},[_c('div',{on:{"click":function($event){return _vm.showMore(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Show More'))+" ")])])])])])}),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2 mt-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],2)])]),_c('multi-document-preview-modal'),_c('note-view-edit-modal',{on:{"saved":function($event){return _vm.fetchList()},"close":_vm.onCloseForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }