import { stringify } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'
import API from '@axios'

const API_URL = '/api/info'

export default {
  ...apiHelper(API_URL),

  getRelatedEntityInfo: (
    owningSchema,
    owningTable,
    relatedSchema,
    relatedTable,
    query = {},
  ) => API.get(`${API_URL}/from/${owningSchema}/${owningTable}/of/${relatedSchema}/${relatedTable}?${stringify(query)}`),
}
