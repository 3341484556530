<template>
  <span>
    <span v-if="type === CustomFieldTypes.Date">
      {{ value ? $d(new Date(value), 'short') : '' }}
    </span>
    <span v-else-if="type === CustomFieldTypes.Percent">
      {{ value ? $n(value * 100) + '%' : '' }}
    </span>
    <span v-else-if="type === CustomFieldTypes.Bool">
      <feather-icon
        :icon="booleanIcon[value]"
        size="17"
      />
    </span>
    <span v-else-if="type === CustomFieldTypes.MultiSelect">
      {{ advancedFieldValue(value) }}
    </span>
    <span v-else>{{ value }}</span>
  </span>
</template>

<script>
import { advancedFieldValue } from '@/helpers/customFields'
import CustomFieldTypes from '@/constants/customFieldTypes'

export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      // type any
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      CustomFieldTypes,
      booleanIcon: {
        true: 'CheckCircleIcon',
        false: 'XCircleIcon',
      },
    }
  },

  methods: {
    advancedFieldValue,
  },
}
</script>
