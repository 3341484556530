<template>
  <div class="toast-notification__container" @click="redirectToNotificationSource">
    <div class="toast-notification__container__header">
      <span>{{ message.subject }}</span>
      <feather-icon
        icon="XIcon"
        size="19"
        class="cursor-pointer"
        @click="$emit('close-toast')"
      />
    </div>
    <div class="toast-notification__container__body">{{ message.message }}</div>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { getNotificationRoute } from '@/helpers/notificationRouteMap'

export default {
  components: { FeatherIcon },
  props: {
    message: {
      type: Object,
      required: true,
    },
    myRouter: {
      type: Object,
      required: false,
      default: null,
    },
  },

  mounted() {
    const routeTarget = this.message.subject.match(/.*?-->(.+)<--$/)
    if (routeTarget) {
      Object.assign(this.message, { route: routeTarget[1] })
      Object.assign(this.message, { subject: routeTarget[0].replace(/-->.+<--$/, '') })
    } else {
      Object.assign(this.message, { route: null })
    }
  },

  methods: {
    redirectToNotificationSource() {
      if (this.message.route && this.myRouter) {
        const route = getNotificationRoute(this.message.route)
        this.myRouter.push(route)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/master-variables.scss';
.toast-notification__container {
  width: 100%;
  max-width: 326px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 1.3rem;
    font-weight: 700;
    background: var(--colour--primary);
    color: var(--colour--white);
  }

  &__body {
    font-size: 1.1rem;
    padding: 15px;
    color: var(--colour--text-body);
  }
}
</style>
