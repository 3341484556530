<template>
  <prozess-sidebar-modal-wrapper
    id="filter-form"
    :visible="visible"
    :editing="editing"
    :loading="loading"
    :is-view-only="false"
    :saving="loading"
    :saving-disabled="false"
    form-title="Filter"
    form-icon="FilterIcon"
    save-label="Apply"
    @submit="handleSave"
    @close="close"
  >
    <form class="tw-px-4" autocomplete="off" style="flex: 1" @submit.prevent>
      <div class="form-container tw-my-4 ">
        <custom-input-field
          ref="customField"
          :is-widget="true"
          :data="dataField"
          :placeholder="$t('Enter a value')"
          @fieldError="
            (val) =>
              typeof val === 'boolean'
                ? (customInputError = '')
                : (customInputError = val)
          "
          @input="(val) => (form.value = val)"
        />
        <span v-if="$hasError('value')" class="invalid-feedback">{{
          $t($hasError('value'))
        }}</span>
        <div>
          <b-input-group class="input-group-merge form-sidebar__add-user">
            <b-input-group-prepend is-text>
              <feather-icon icon="ToolIcon" />
            </b-input-group-prepend>
            <v-select
              v-model="form.condition"
              field="condition"
              name="condition"
              style="flex: 1"
              :searchable="false"
              :clearable="false"
              :placeholder="$t('Conditions')"
              :options="conditionsFilter"
              :get-option-label="(option) => option.label"
            />
          </b-input-group>
          <span v-if="$hasError('condition.label')" class="invalid-feedback">{{
            $t($hasError('condition.label'))
          }}</span>
        </div>

        <!-- START: user fields -->
        <div class="checkbox-list-container">
          <div class=" checkbox-list-container__header">
            <span>{{ $t('Available user fields') }}</span>
          </div>
          <b-form-group
            v-if="matchingFields.length"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              :disabled="form.userAccessible"
              :aria-describedby="ariaDescribedby"
              class="checkbox-list-container__checkbox-container"
            >
              <b-form-radio
                v-for="field in matchingFields"
                :key="field.key"
                v-model="form.selectedUser"
                field="selectedUser"
                :value="field"
                class="tw-my-2"
              >
                {{ $t(field.label) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <div v-else class="tw-ml-4 tw-pt-4">
            {{ $t('No matching records found') }}
          </div>
          <div
            v-if="matchingFields.length"
            class="checkbox-list-container__footer"
          >
            <b-form-checkbox
              v-model="form.userAccessible"
              switch
              @input="makeUserAccessible"
            >
              <span class="tw-text-xs">{{ $t('Make user accessible') }}</span>
            </b-form-checkbox>
            <!-- END: user fields -->
          </div>
        </div>
        <span v-if="$hasError('selectedUser.label')" class="invalid-feedback">{{
          $t($hasError('selectedUser.label'))
        }}</span>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import ProzessSidebarModalWrapper from '@/@core/components/ProzessSidebarModalWrapper.vue'
import { AdvancedSearchCondition } from '@/constants/advancedSearch'
import RootEvents from '@/constants/rootEvents'
import vSelect from 'vue-select'
import authUser from '@/services/authUser'
import { filterInitialForm } from '@/constants/widgets'
import { Entity, Schema } from '@/constants/app'
import { getLinkableFieldTypes } from '@/helpers/form'
import { filterFormSchema } from '@/schema/widget'
import CustomInputField from '@/components/Dashboard/CustomInputField.vue'

export default {
  components: { ProzessSidebarModalWrapper, vSelect, CustomInputField },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    viewing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$lodash.cloneDeep(filterInitialForm),

      userFields: [],
      loading: false,
      editing: true,
      conditionOptions: Object.keys(AdvancedSearchCondition).map(key => ({
        label: AdvancedSearchCondition[key],
        value: key,
      })),
      dataField: {},
      fieldId: null,
      type: null,
      errors: [],
      customInputError: '',
    }
  },

  computed: {
    conditionsFilter() {
      if (this.type === 'BOOL') {
        return this.$lodash.takeRight(this.conditionOptions, 2)
      }

      return this.conditionOptions.slice(0, this.conditionOptions.length - 2)
    },
    matchingFields() {
      if (this.fieldId === `${Schema.CRM}.${Entity.COMPANY}.name`) {
        return this.userFields.filter(({ key }) => key === 'systemCompanyName')
      }

      const allowedTypes = getLinkableFieldTypes(this.type)

      return this.userFields.filter(
        ({ dataType }) => !allowedTypes.length || allowedTypes.includes(dataType),
      )
    },

    isInputDisable() {
      if (!this.form.condition.value) return false
      return (
        this.form.condition?.value.includes('IS_')
        || !!this.form.selectedUser.label
      )
    },
  },

  watch: {
    isInputDisable: {
      handler(newVal, oldVal) {
        if (newVal) this.form.value = ''
      },
    },
  },

  mounted() {
    this.getUserFields()
  },

  methods: {
    async handleSave() {
      this.$refs.customField.validate()
      const form = {
        ...this.form,
        matchingFields: this.matchingFields,
      }
      this.errors = await this.yupValidate(filterFormSchema, form)

      const payload = {
        ...form,
        operationValue: form.condition?.label.toUpperCase(),
        paramValue: form.value || form.selectedUser.key,
        isParamUserField: !!form.selectedUser.key,
      }
      if (this.errors.length || this.customInputError) return false

      this.$emit('save', payload)
    },
    makeUserAccessible() {
      this.form.selectedUser = { label: '' }
    },
    async getUserFields() {
      const { response } = await this.$async(authUser.getUserFields())
      this.userFields = response.data
    },
    async setData(data) {
      this.dataField = { ...data, paramValue: data.form.value }
      const { form, key, type } = this.$lodash.cloneDeep(data)
      this.form = form
      this.fieldId = key
      this.type = type
    },

    close() {
      this.errors = []
      this.form = this.$lodash.cloneDeep(filterInitialForm)
      this.$root.$emit(RootEvents.BASIC_ASSIGN_VALUE, {
        name: 'showFilterForm',
        value: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
@import '@/assets/scss/master-variables.scss';
.form-container {
  display: flex;
  color: var(--colour-text-muted);
  flex-direction: column;
  margin: 20px auto;
  > * {
    width: 100%;
    margin: 0.5rem auto;
  }
  &__header-title {
    white-space: normal !important;
  }

  .checkbox-list-container {
    margin-top: 25px;
  }
}
</style>
