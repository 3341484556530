import * as yup from 'yup'
import { customFieldsSchema } from '@/schema/customFields'
import dayjs from 'dayjs'
import { shapeSchemaByMetadata } from '@/helpers/field'

export const todoSchema = shapeSchemaByMetadata

export default {
  todoSchema,
}
