import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'

const API_URL = '/api/crm/commissions'

export default {
  ...apiHelper(API_URL),
  getAllCommissions(query) {
    return API.get(`${API_URL}?${toQuery(query)}`)
  },

  getCommission(id) {
    return API.get(`${API_URL}/${id}`)
  },

  getCommissionHistory(id) {
    return API.get(`${API_URL}/${id}/history`)
  },

  createNewCommission(payload) {
    return API.post(`${API_URL}`, payload)
  },

  updateCommission(id, payload) {
    return API.put(`${API_URL}/${id}`, payload)
  },

  exportCommissionByYear(query) {
    return API.put(`${API_URL}/export/initiate-yearly?${toQuery(query)}`)
  },

  exportCommissionByPeriod(query) {
    return API.put(`${API_URL}/export/initiate?${toQuery(query)}`)
  },

  deleteCommission(id) {
    return API.delete(`${API_URL}/${id}`)
  },
}
