import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'

const API_URL = '/api/sales'

export default {
  ...apiHelper(API_URL),

  getAllSalesByJob(jobId, tableName, query) {
    return API.get(`${API_URL}/imports/${jobId}/custom_schema/${tableName}?${toQuery(query)}`)
  },
  getAllSellersByJob(jobId, tableName, query) {
    return API.get(`${API_URL}/imports/${jobId}/custom_schema/${tableName}/sellers?${toQuery(query)}`)
  },
  getDuplicates(jobId, tableName, query) {
    return API.get(`${API_URL}/imports/${jobId}/custom_schema/${tableName}/duplicates?${toQuery(query)}`)
  },

  getSalesWithoutSellers(jobId, tableName, query) {
    return API.get(`${API_URL}/imports/${jobId}/custom_schema/${tableName}/orphans?${toQuery(query)}`)
  },

  getAllSalesBySeller(tableName, uuidKey, query) {
    return API.get(`${API_URL}/custom_schema/${tableName}/contacts/${uuidKey}?${toQuery(query)}`)
  },

  getAllSalesBySellerFromAllTables(uuidKey, query) {
    return API.get(`${API_URL}/contacts/${uuidKey}?${toQuery(query)}`)
  },

  getAllSalesByCompanyFromAllTables(uuidKey, query) {
    return API.get(`${API_URL}/company/${uuidKey}?${toQuery(query)}`)
  },

  addSale(tableName, payload) {
    return API.post(`${API_URL}/custom_schema/${tableName}`, payload)
  },

  updateSale(tableName, uuidKey, versionNumber, payload) {
    return API.put(`${API_URL}/custom_schema/${tableName}/${uuidKey}?versionNumber=${versionNumber}`, payload)
  },

  deleteSale(tableName, uuidKey, versionNumber) {
    return API.delete(`${API_URL}/custom_schema/${tableName}/${uuidKey}?versionNumber=${versionNumber}`)
  },

  deleteSalesImport(jobId, tableName) {
    return API.delete(`${API_URL}/${jobId}/custom_schema/${tableName}`)
  },
}
