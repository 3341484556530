<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading || formDataLoading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="inquirySidebar"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <template v-if="resourceId">
        <prozess-input
          id="refId"
          v-model="form.refId"
          :placeholder="$t('Reference Number')"
          field="refId"
          name="refId"
          disabled
        />
        <prozess-input
          id="createdDate"
          v-model="form.createdDate"
          :placeholder="$t('Date Time')"
          field="createdDate"
          name="createdDate"
          disabled
        />
        <prozess-input
          id="createdByUserName"
          v-model="form.createdByUserName"
          :placeholder="$t('Created By')"
          field="createdByUserName"
          name="createdByUserName"
          disabled
        />
      </template>
      <div v-if="$lodash.get(form, 'contact.isNew', false)">
        <div class="pb-1">
          <h3>{{ $t('New Contact') }}</h3>
        </div>
        <prozess-input
          v-model="newContact.firstName"
          :placeholder="$t('First Name')"
          icon="UserIcon"
          field="contactFirstName"
          name="contactFirstName"
          :error="$hasError('newContact.firstName')"
          @enter="save"
        />
        <prozess-input
          v-model="newContact.lastName"
          :placeholder="$t('Last Name')"
          icon="UserIcon"
          field="contactLastName"
          name="contactLastName"
          :error="$hasError('newContact.lastName')"
          :hint="'*' + $t('Required')"
          @enter="save"
        />
        <template v-if="contactCustomFields.length && !loading">
          <h4 class="mb-2">{{ $t('Contact Custom Fields') }}</h4>
          <div>
            <prozess-custom-fields
              v-model="newContact.customFields"
              :custom-fields="contactCustomFields"
              :errors="errors"
              error-prefix="newContact.customFields"
              @enter="save"
            />
          </div>
        </template>
      </div>
      <div
        v-for="field in filteredDuplicatedField"
        :key="field.key"
      >
        <AppDynamicField
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <company-search
        id="company-search"
        v-model="form.company"
        :placeholder="$t('Search Company')"
        :can-handle-new-item="false"
        :error="$hasError('company')"
        class="mb-1"
        :autocomplete-api="isCrm ? null : search => autocompleteCompanyApi(search)"
        icon="SearchIcon"
        @input="handleCompanyInput"
      />
      <contact-search
        id="contact-search"
        v-model="form.contact"
        :autocomplete-api="isCrm ? null : search => autocompleteContactApi(search)"
        :placeholder="$t('Search Contact')"
        :can-handle-new-item="true"
        :error="$hasError('contact')"
        class="mb-1"
        :hint="'*' + $t('Required')"
        :custom-selected-label="item => (item.firstName || '') + ' ' + item.lastName"
        :initial-options="initialContactOptions"
        icon="SearchIcon"
        @input="handleContactInput"
      />
      <prozess-field-wrapper
        v-if="showUnivFields"
        :icon="MetastatusTypeIcons['status']"
        :error="$hasError('statusId')"
      >
        <prozess-select
          v-model="form.statusId"
          style="flex: 1"
          :placeholder="$t('Status')"
          :options="statusOptions"
          :reduce="option => option.metaStatusId"
          label="metaStatusName"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <prozess-field-wrapper
        v-if="showUnivFields"
        :icon="MetastatusTypeIcons['priority']"
        :error="$hasError('priority')"
      >
        <prozess-select
          v-model="form.priorityId"
          style="flex: 1"
          :placeholder="$t('Priority')"
          :options="priorityOptions"
          :reduce="option => option.metaStatusId"
          label="metaStatusName"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <prozess-field-wrapper
        v-if="showUnivFields"
        :icon="MetastatusTypeIcons['category']"
        :error="$hasError('categoryId')"
      >
        <prozess-select
          v-model="form.categoryId"
          style="flex: 1"
          :placeholder="$t('Category')"
          :options="newCategoryOptions"
          :reduce="option => option.metaStatusId"
          label="metaStatusName"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <prozess-field-wrapper
        v-if="showUnivFields"
        :icon="MetastatusTypeIcons['source']"
        :error="$hasError('sourceId')"
      >
        <prozess-select
          v-model="form.sourceId"
          style="flex: 1"
          :placeholder="$t('Source')"
          :options="sourceOptions"
          :reduce="option => option.metaStatusId"
          label="metaStatusName"
          :clearable="false"
        />
      </prozess-field-wrapper>
      <prozess-field-wrapper
        icon="UserIcon"
        :error="$hasError('user')"
      >
        <prozess-select
          v-model="form.user"
          style="flex: 1"
          :placeholder="$t('Assigned To')"
          :options="userOptions"
          :reduce="option => option.userUuid"
          :get-option-label="option => option.firstName + ' ' + option.lastName"
        >
          <template #option="option">
            <div class="d-flex flex-column">
              <span>{{ option.firstName + ' ' + option.lastName }}</span>
              <span>{{ option.email }}</span>
            </div>
          </template>
        </prozess-select>
      </prozess-field-wrapper>

      <form-notification
        v-if="resourceId"
        :id="resourceId"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { preventSpecialChars } from '@/helpers/app'
import { inquirySchema } from '@/schema/inquiry'
import { metastatusService } from '@/services/metastatus'
import { MetastatusTypeIcons } from '@/constants/metastatusTypes'
import { fetchBackendErrors } from '@/helpers/backendValidations'
import FormNotification from '@/components/Forms/FormNotification.vue'
import ProzessInput from '@core/components/ProzessInput.vue'
import inquiryService from '@/services/inquiry'
import contactService from '@/services/contact'
import companyService from '@/services/company'
import CompanySearch from '@/views/shared/CompanySearch.vue'
import ContactSearch from '@/views/shared/ContactSearch.vue'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import fieldMixins, { customFieldsMixins } from '@/mixins/fields'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'
import { fillableOnly } from '@/helpers/field'
import dayjs from 'dayjs'
import settingsService from '@/services/settings'
import requiredValidatorMixin from '@/mixins/requiredValidator'
import moment from 'moment'

const defaultNewContact = {
  firstName: '',
  lastName: '',
  customFields: {},
  contactVersion: 1,
}

const staticField = {
  company: 'company',
  contact: 'contact',
  statusId: 'statusId',
  priorityId: 'priorityId',
  categoryId: 'categoryId',
  sourceId: 'sourceId',
  user: 'user',
  inquiryId: 'inquiryId',
  refId: 'refId',
  createdDate: 'createdDate',
  createdByUserName: 'createdByUserName',
}

export default {
  name: 'InquiryForm',
  components: {
    FormNotification,
    AppDynamicField,
    ContactSearch,
    CompanySearch,
    ProzessInput,
  },
  mixins: [fieldMixins, customFieldsMixins, requiredValidatorMixin],
  props: {
    formDataLoading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    priorityOptions: {
      type: Array,
      default: () => [],
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    sourceOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MetastatusTypeIcons,
      newContact: defaultNewContact,
      resource: {
        statusId: null,
        priorityId: null,
        categoryId: null,
        sourceId: null,
        customFields: {},
        receivedDate: null,
        dueDate: null,
      },
      standardKeyMapping: staticField,
      form: {},
      options: [],
      serviceName: inquiryService,
      initialContactOptions: [],
      saving: false,
      loading: true,
      errors: [],
      version: '',
      customFields: [],
      metastatusService: null,
      userOptions: [],
      contactCustomFields: [],
      filteredDuplicatedField: [],
      fieldMetadata: [],
      showUnivFields: true,
    }
  },
  computed: {
    isCrm() {
      return ['contact-view', 'company-view'].includes(this.$route.name)
    },
    formTitle() {
      const action = this.resourceId ? 'Edit' : 'Add New'
      return `${this.$t(action)} ${this.$t('Inquiry')}`
    },
    isValidDate() {
      const dueDate = this.form.due_date
      const receivedDate = this.form.received_date
      return moment(dueDate).format('YYYY-DD-MM') === moment(receivedDate).format('YYYY-DD-MM') ? false : moment(dueDate).isSameOrBefore(receivedDate)
    },
    newCategoryOptions() {
      return this.categoryOptions.filter(
        v => {
          const entityIndex = v.entities.findIndex(e => (e.schemaName === 'core' && e.tableName === 'inquiry'))
          return entityIndex >= 0
        },
      )
    },

  },
  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getInitialData()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.metastatusService = metastatusService('core')
  },
  methods: {
    handleContactInput(data) {
      const label = this.$t('New Contact')
      if (data?.isNew && data?.value !== label) {
        this.$set(this.form, 'contact', {
          ...data,
          value: label,
        })
      }
    },
    async getShowUnivFields() {
      const { response: showUnivSettingRes } = await this.$async(settingsService.getOne('SHOW_UNIV_FIELDS_INQUIRY'))
      this.showUnivFields = showUnivSettingRes?.data
      console.log('showUnivFields', this.showUnivFields)
    },
    async handleCompanyInput(data) {
      const id = data?.value?.uuid
      if (id) {
        const { response } = await this.$async(inquiryService.getCompanyContacts(id, {}, this.isCrm))
        this.initialContactOptions = response.data.pageItems.map(({ contact }) => ({
          isNew: false,
          label: `${contact.firstName} ${contact.lastName}`,
          value: contact,
        }))
      } else {
        this.initialContactOptions = []
      }
    },
    autocompleteUserApi(search) {
      return inquiryService.searchUser({
        query: search.trim(),
      })
    },
    autocompleteContactApi(search) {
      return inquiryService.searchCrm('crm', 'contact', {
        query: search.trim(),
      })
    },
    autocompleteCompanyApi(search) {
      return inquiryService.searchCrm('crm', 'company', {
        query: search.trim(),
      })
    },
    async getInitialData() {
      try {
        this.loading = true

        const { response } = await this.$async(
          advancedFieldMgmt.getFieldsByEntity({
            schema: 'core',
            table: 'inquiry',
          }),
        )

        await this.getShowUnivFields()
        const fieldMetadata = fillableOnly(response.data)

        this.filteredDuplicatedField = fieldMetadata.filter(fieldFilter => !Object.values(staticField).some(val => val === fieldFilter.key))
        const standardFields = Object.keys(staticField).map(key => ({ key }))
        const fieldsCombine = [...fieldMetadata, ...standardFields]
        fieldsCombine.forEach(field => {
          if (field.type === 'BOOL') {
            this.$set(this.form, field.key, false)
          } else this.$set(this.form, field.key, null)
        })

        this.fieldMetadata = this.$lodash.cloneDeep(fieldMetadata)
        const [defaultMetastatusRes, customFieldsRes, userRes, contactCustomFieldsRes] = await Promise.all([
          this.metastatusService.getDefaults('inquiry'),
          inquiryService.getCustomFields(),
          inquiryService.searchUser({ query: '' }, this.isCrm),
          contactService.getCustomFields(),
        ])
        this.userOptions = userRes?.data
        this.loadDefaultMetastatus(defaultMetastatusRes?.data)
        this.customFields = customFieldsRes?.data.sort((a, b) => b.favorite - a.favorite)
        this.contactCustomFields = contactCustomFieldsRes?.data.filter(item => item.required)
        // this.resource.receivedDate = new Date()
        if (this.resourceId) {
          await this.fetchData()
        } else if (this.isCrm) {
          if (this.$route.name === 'contact-view') {
            const { data: contactData } = await contactService.getOne(this.$route.params.id)
            const { firstName, lastName } = contactData
            this.form.contact = {
              isNew: false,
              label: `${firstName || ''} ${lastName}`,
              value: {
                firstName: firstName || '',
                lastName,
                uuid: this.$route.params.id,
              },
            }
          }
          if (this.$route.name === 'company-view') {
            const { data: companyData } = await companyService.getOne(this.$route.params.id)
            const { name } = companyData
            this.form.company = {
              isNew: false,
              label: name,
              value: {
                uuid: this.$route.params.id,
              },
            }
          }
        }
      } finally {
        if (!(this.form?.received_date)) {
          const receivedDateField = this.filteredDuplicatedField.find(e => e.key === 'received_date')
          if (receivedDateField) {
            this.form.received_date = dayjs().format('YYYY-MM-DDTHH:mmZ')
          } else {
            this.form.received_date = dayjs('1970-01-01 00:00').format('YYYY-MM-DDTHH:mmZ')
          }
        }
        this.loading = false
      }
    },

    async fetchData() {
      const { response } = await this.$async(this.serviceName.getOne(this.resourceId))

      const data = response.data

      this.version = data.version
      this.formatAsFormData(data, 'customFieldData')
      this.form.contact = {
        isNew: false,
        label: `${data.contactFirstName} ${data.contactLastName}`,
        value: {
          firstName: data.contactFirstName,
          lastName: data.contactLastName,
          uuid: data.contactUuid,
        },
      }
      this.form.createdDate = response.data.createdDate ? dayjs(response.data.createdDate).format('MMMM DD, YYYY HH:mm') : ''

      this.form.user = data.assignedUserUuid
      this.form.company = data.companyUuid
        ? {
            isNew: false,
            label: data.companyName,
            value: {
              uuid: data.companyUuid,
            },
          }
        : null
    },
    loadDefaultMetastatus(data) {
      const types = ['status', 'category', 'priority', 'source']
      types.forEach(type => {
        const value = data[type]
        if (!value) return
        this.form[`${type}Id`] = value.metaStatusId
      })
    },

    preventSpecialChars,

    handleGroupInput() {
      this.$forceUpdate()
    },
    reset() {
      this.errors = []
      this.resource = {
        customFields: {},
      }
      this.form = {}
      this.newContact = this.$lodash.cloneDeep(defaultNewContact)
      this.customFields = []
      this.loading = false
      this.saving = false
    },
    async getOne() {
      const { response } = await this.$async(
        this.isCrm
          ? inquiryService.getOneByEntity({
              schemaName: 'crm',
              id: this.resourceId,
            })
          : inquiryService.getOne(this.resourceId),
      )
      const { data } = response

      this.customFields = data.customFieldMetadata.sort((a, b) => b.favorite - a.favorite)

      const customFields = {
        ...data.customFieldMetadata.reduce((acc, customField) => {
          const result = data.customFieldData.find(item => customField.id === item.metadataId)
          if (result) {
            acc[customField.key] = result.value
          }
          return acc
        }, {}),
      }

      this.resource = {
        ...data,
        company: data.companyUuid
          ? {
              isNew: false,
              label: data.companyName,
              value: {
                uuid: data.companyUuid,
              },
            }
          : null,
        contact: {
          isNew: false,
          label: `${data.contactFirstName} ${data.contactLastName}`,
          value: {
            firstName: data.contactFirstName,
            lastName: data.contactLastName,
            uuid: data.contactUuid,
          },
        },
        user: data.assignedUserUuid,
        customFields,
        inquiryVersion: data.version,
      }
    },
    close() {
      this.$emit('close')
    },
    async save() {
      let { contact } = this.form

      contact = contact?.isNew ? contact?.value : contact?.value?.uuid

      const staticFieldValidations = [
        {
          required: true,
          key: 'priorityId',
          type: 'TEXT',
        },
        {
          required: true,
          key: 'contact',
          type: 'TEXT',
        },
        {
          required: true,
          key: 'statusId',
          type: 'TEXT',
        },
      ]
      this.form.receivedDate = this.form.received_date
      this.errors = await this.yupValidate(inquirySchema([...this.fieldMetadata, ...staticFieldValidations]), { ...this.form, contact })
      if (this.isValidDate && this.$lodash.get(this.resource, 'values', []).length === 0) {
        const index = this.errors.length === 0 ? 0 : this.errors.length - 1
        this.$set(this.errors, index, {
          field: 'due_date',
          defaultMessage: this.$t('Must be later than or equal to received date'),
        })
      } else if (this.form.due_date === 'Invalid Date') {
        const index = this.errors.length === 0 ? 0 : this.errors.length - 1
        this.$set(this.errors, index, {
          field: 'due_date',
          defaultMessage: this.$t('Invalid date'),
        })
      }

      // this.errors = await this.yupValidate(
      //   inquirySchema({
      //     $t: v => this.$t(v),
      //     $d: v => this.$d(v, 'short'),
      //     customFields: this.customFields,
      //     receivedDate: this.resource.receivedDate,
      //     isNewContact: this.resource?.contact?.isNew,
      //     contactCustomFields: this.contactCustomFields,
      //   }),
      //   {
      //     ...this.resource,
      //     contact,
      //     newContact: this.newContact,
      //   },
      // )
      if (this.errors.length > 0) return

      this.saving = true
      const payload = {
        ...this.formatAsPayload(),
        existingCompanyUuid: this.form.company?.value?.uuid,
        assignUserUuid: this.form.user,
        inquiryVersion: this.version,
        dueDate: this.form.due_date,
      }
      if (!(payload?.receivedDate)) {
        Object.assign(payload, { receivedDate: this.form.received_date })
      }
      if (!(payload?.title)) {
         Object.assign(payload, { title: this.form?.title ?? 'No title' })
       }

      if (this.form.contact?.isNew) {
        payload.newContact = this.newContact
      } else {
        payload.existingContactUuid = contact
      }
      Object.values(this.customFields).filter(cf => cf.dateAutoset).forEach(cc => {
        const dateNow = new Date()
        if (cc.fieldType === 'DATE') {
          payload.customFields[cc.key] = moment(dateNow).format('YYYY-MM-DD')
        } else if (cc.fieldType === 'DATETIME') {
          payload.customFields[cc.key] = moment(dateNow).format('YYYY-MM-DDTHH:mmZ')
        }
      })
      const { error } = await this.$async(this.createOrUpdate(payload))
      if (error) {
        const errorMessage = fetchBackendErrors(error, this.resourceId)
        this.saving = false
        return this.showWarning(errorMessage)
      }
      this.$emit('saved')
      this.close()

      this.saving = false
    },
    createOrUpdate(data) {
      // if(!this.$route.name === 'inquiries') {
      //   return this.resourceId ? inquirySchema.updateByEntity(this.schemaName,)
      // }
      return this.resourceId
        ? inquiryService.update(this.resourceId, data, this.isCrm)
        : inquiryService.store(
            {
              ...data,
              inquiryVersion: 1,
            },
            this.isCrm,
          )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/form-sidebar.scss';
</style>
