<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t(placeholder)"
      :label="label || $t('CONTACT')"
      :loading="contactSearchLoading"
      :new-item-message="$t('Add new contact')"
      :can-handle-new-item="canHandleNewItem"
      :options="contacts"
      :icon="icon"
      :error="error"
      :hint="hint"
      :disabled="disabled"
      :initial-options="initialOptions"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import contactService from '@/services/contact'

export default {
  props: {
    icon: {
      type: String,
      default: 'UserIcon',
    },
    autocompleteApi: {
      type: Function,
      default: search => contactService.autocomplete(search.trim()),
    },
    initialOptions: {
      type: Array,
      default: () => [],
    },
    canHandleNewItem: {
      type: Boolean,
      default: true,
    },
    customSelectedLabel: {
      type: Function,
      default: item => item.firstName,
    },
    placeholder: {
      default: 'First Name',
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      contacts: [],
      contactSearch: '',
      contactSearchLoading: false,
    }
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.contacts = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.contacts = []
    },
    async handleSearch(search) {
      if (search.trim() === '') return
      this.contactSearchLoading = true
      const { response } = await this.$async(this.autocompleteApi != null ? this.autocompleteApi(search) : contactService.autocomplete(search.trim()))
      this.contacts = response.data.map(item => ({
        label: `${item.firstName || ''} ${item.lastName}`,
        selectedLabel: this.customSelectedLabel(item) || this.$t('(No first name)'),
        additionalLabels: ['email', 'connectedCompanies.name'],
        value: item,
      }))
      this.contactSearchLoading = false
    },
  },
}
</script>
