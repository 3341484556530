<template>
  <div>
    <sidebar-form
      :form-data-loading="metastatusLoading"
      :visible="showForm"
      :resource-id="resourceId"
      :entity-id="entityId"
      :entity-type="entityType"
      :schema-name="schemaName"
      :status-options="statusOptions"
      :priority-options="priorityOptions"
      :category-options="categoryOptions"
      :source-options="sourceOptions"
      @saved="fetchList()"
      @close="onCloseForm"
    />
    <sidebar-view
      :visible="showView"
      :resource-id="resourceId"
      @close="onViewClose"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="height: 100%;"
      class="d-flex flex-column"
    >
      <div class="card resource-view__scrollable">
        <div class="card-body">
          <div class="mb-2">
            <button
              id="create-todo-button"
              class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary"
              :class="{
                'icon-disabled': canUpdate === false,
              }"
              @click="canUpdate && create()"
            >
              <feather-icon icon="UserPlusIcon" class="mr-50" />
              <span>{{ $t('Add To Do') }}</span>
            </button>
            <b-tooltip target="create-todo-button">
              {{
                canUpdate === true
                  ? $t('Add To Do')
                  : $t("You don't have permission to add todo")
              }}
            </b-tooltip>
          </div>
          <div
            v-for="item of items"
            :key="item.vueKey"
            class="prozess-company-contact__item"
          >
            <div
              class="prozess-company-contact__summary d-flex justify-content-center align-items-center"
            >
              <div
                class="prozess-company-contact__item-name"
                :style="{ flex: 0.66 }"
              >
                <prozess-resource-name
                  :has-permission="$can('Read', 'Todo')"
                  :to="{ name: 'todo-view', params: { id: item.uuid, tab: GenericTabs.OVERVIEW_360 } }"
                  :title="item.title"
                />
              </div>
              <div
                class="prozess-company-contact__item-description line-clamp-1 pr-3"
              >
                {{ generateDescription(item) }}
              </div>
              <div
                class="prozess-company-contact__item-right-buttons d-flex align-items-center"
              >
                <div
                  class="prozess-company-contact__item-action-buttons d-flex"
                >
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`popup-row-${item.uuid}-preview-icon`"
                      icon="InfoIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      @click="view(item.uuid)"
                    />
                    <b-tooltip
                      :title="$t('View details')"
                      :target="`popup-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                    <feather-icon
                      :id="`edit-row-${item.uuid}-preview-icon`"
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{
                        'icon-disabled': canUpdate === false,
                      }"
                      @click="canUpdate && edit(item.uuid)"
                    />
                    <b-tooltip
                      :title="
                        canUpdate === true
                          ? $t('Edit')
                          : $t('You don\'t have permission to update todo')
                      "
                      :target="`edit-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                    <feather-icon
                      :id="`delete-row-${item.uuid}-preview-icon`"
                      icon="TrashIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{
                        'icon-disabled': canUpdate === false,
                      }"
                      @click="confirmRemoval(item)"
                    />
                    <b-tooltip
                      :title="
                        canUpdate === true
                          ? $t('Remove')
                          : $t('You don\'t have permission to delete todo')
                      "
                      :target="`delete-row-${item.uuid}-preview-icon`"
                      placement="left"
                    />
                  </div>
                </div>
                <div class="prozess-company-contact__item-show-more ml-2">
                  <div
                    :class="{
                      'icon-disabled': !item.canShowMore(),
                    }"
                    @click="item.canShowMore() && collapse(item)"
                  >
                    {{ $t('Show More') }}
                    <feather-icon icon="ChevronDownIcon" />
                  </div>
                </div>
              </div>
            </div>
            <b-collapse :visible="item.visible">
              <div class="prozess-company-contact__item-details mt-2">
                <prozess-custom-field-data
                  v-for="(field, i) of item.showMoreFields()"
                  :key="`extra-field-${i}`"
                  :item="field"
                />
              </div>
            </b-collapse>
          </div>
          <prozess-pagination
            v-if="!loading"
            class="mx-2 mb-2 mt-2"
            :class="{ 'd-none': !total }"
            :meta="dataMeta"
            :page="currentPage"
            :total="total"
            :size="perPage"
            @change="handleCurrentPage"
          />
        </div>
      </div>
    </b-overlay>
    <multi-document-preview-modal />
  </div>
</template>

<script>
import { GenericTabs, Schema } from '@/constants/app'
import { swalConfirmDeleteOption } from '@/helpers/app'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import SidebarForm from '@/views/pages/Todo/Form.vue'
import SidebarView from '@/views/pages/Todo/SidebarView.vue'
import todoService from '@/services/todo'
import listMixinFactory from '@/mixins/list'
import detachOrDeleteMixinFactory from '@/mixins/detachOrDeleteMixin'
import MultiDocumentPreviewModal from '@/views/shared/MultiDocumentPreviewModal.vue'

export default {
  components: {
    SidebarForm,
    SidebarView,
    MultiDocumentPreviewModal,
  },
  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      autoRouteName: true,
      service: todoService,
      listFormat: true,
      disableAutoFetchlist: true,
    }),
    detachOrDeleteMixinFactory({
      uuidKey: 'uuid',
      versionKey: 'version',
    }),
  ],

  props: {
    entityType: {
      type: String,
      required: true,
    },

    entityId: {
      type: String,
      required: true,
    },

    schemaName: {
      type: String,
      default: Schema.CRM,
    },
  },

  data() {
    return {
      GenericTabs,
      loading: false,
      showForm: false,
      showView: false,
      resourceId: null,
      routeParams: {
        tab: 'todo',
      },
    }
  },
  computed: {
    canUpdate() {
      if (this.schemaName === Schema.CUSTOM) {
        return this.$can('Update', this.entityType)
      }

      return this.$can('Update', 'CRM')
    },

    detachOrDelete() {
      return todoService.detachOrDelete
    },

    canDetachOrDelete() {
      return this.canUpdate
    },

    handleDeletionCompletion() {
      return this.fetchList
    },
  },

  watch: {
   '$route.params': {
      handler(val) {
         if (val?.tab === GenericTabs.TODO) {
            this.getMetastatusInitialData()
         }
      },
   },
  },

  methods: {
    view(uuid) {
      this.resourceId = uuid
      this.showView = true
    },

    onViewClose() {
      this.showView = false
      this.resourceId = null
    },

    collapse(item) {
      this.$set(item, 'visible', !item.visible)
    },

    confirmRemoval({ uuid, version }) {
      if (!this.canUpdate) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.confirmAttachmentRemoval(uuid, version)
        }
      })
    },

    create() {
      this.showForm = !this.showForm
    },

    edit(uuid) {
      this.resourceId = uuid
      this.showForm = true
    },

    generateDescription(item) {
      const fullname = `${item.contactFirstName} ${item.contactLastName}`
      return item.companyName ? `${item.companyName} / ${fullname}` : fullname
    },

    async getAllMethod(query) {
      const { response } = await this.$async(todoService.getCustomFields())

      this.customFields = this.$customFields.sort(response.data)

      return todoService.getAttached(
        {
          entityId: this.entityId,
          tableName: this.entityType,
        },
        query,
      )
    },

    confirmAttachmentRemoval(uuid, version) {
      this.$swal({
        title: this.$t(
          'Do you also want to delete the related Notes and Documents of this to do?',
        ),
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        denyButtonText: this.$t('No'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-secondary',
          denyButton: 'btn btn-dark ml-1',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        const {
          isConfirmed: removeTodoWithDocumentAndNote,
          isDenied: removeTodoOnly,
        } = result

        if (removeTodoOnly) {
          await this.$async(
            todoService.deleteByEntity(
              { id: uuid, schemaName: this.schemaName },
              { todoVersion: version },
            ),
          )

          this.fetchList()
        } else if (removeTodoWithDocumentAndNote) {
          await this.$async(
            todoService.deleteWithDocumentsAndNotesByEntity(
              { id: uuid, schemaName: this.schemaName },
              { todoVersion: version },
            ),
          )

          this.fetchList()
        }
      })
    },

    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },

    routeWatcher(params) {
      if (params.tab === 'todo') {
        this.fetchList()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/company-contact-list.scss';
</style>
