<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="`${$t(titleAction)} ${$t($case.title(type))} ${$t('Relationship')}`"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <div class="mb-2">
        <prozess-field-wrapper class="flex">
          <prozess-select
            v-model="controls.entity1CrmRelationshipUuidKey"
            style="flex: 1"
            :placeholder="$t('Select Relationship')"
            :options="options"
            :searchable="false"
          />
        </prozess-field-wrapper>
      </div>

      <prozess-field-wrapper v-if="this.type === 'company'" class="flex">
        <prozess-select
          v-model="controls.entity1HierarchyType"
          style="flex: 1"
          :placeholder="$t('Select Hierarchy')"
          :options="hierachyTypeOptions"
          :searchable="false"
          :disabled="false"
        />
        <span
          v-if="$hasError('hierachyType')"
          class="invalid-feedback"
        >
          {{ $t($hasError('hierachyType')) }}
        </span>
      </prozess-field-wrapper>

      <div class="mb-2">
        <label class="input__label">
          {{ `${$t('Search')}` }}
        </label>
        <EntityDropdownSearch
          v-model="controls.connectedEntity"
          :options="crmEntityOptions"
          :placeholder="`${$t('Search for')} ${$case.title(type)}`"
          :entity-type="type"
          :schema="Schema.CRM"
          :columns="crmEntityColumns"
          :loading="searching"
          @search="handleSearch"
        />
        <span
          v-if="$hasError('connectedEntityId')"
          class="invalid-feedback"
        >
          {{ $t($hasError('connectedEntityId')) }}
        </span>
      </div>

      <prozess-field-wrapper class="flex">
        <prozess-select
          v-model="controls.entity2CrmRelationshipUuidKey"
          style="flex: 1"
          :placeholder="$t('Select Relationship')"
          :options="options"
          :searchable="false"
          :disabled="!controls.connectedEntity ? true : false"
        />
        <span
          v-if="$hasError('crmRelationshipId')"
          class="invalid-feedback"
        >
          {{ $t($hasError('crmRelationshipId')) }}
        </span>
      </prozess-field-wrapper>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { formatAsRelationshipOptions, formatAsPayloadForMapping } from '@/helpers/crmRelationship'
import { formatAsHierarchyOptions } from '@/helpers/crmRelationshipHierarchy'
import { crmRelationshipMappingSchema } from '@/schema/crmRelationship'
import { Schema } from '@/constants/app'
import crmRelationshipService from '@/services/crmRelationship'
import crmRelationshipMappingService from '@/services/crmRelationshipMapping'
import crmRelationshipHierarchyService from '@/services/crmRelationshipHierarchy'
import EntityDropdownSearch from '@/views/shared/CustomEntity/EntityDropdownSearch.vue'

const defaultResource = {
  entity1CrmRelationshipUuidKey: null,
  entity1Table: null,
  entity1UuidKey: null,
  entity2CrmRelationshipUuidKey: null,
  entity2Table: null,
  entity2UuidKey: null,
  entity1HierarchyType: null,
}

const defaultControls = {
  connected: null,
  relationship: null,
}

export default {
  name: 'CRMRelationshipMappingForm',

  components: {
    EntityDropdownSearch,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    connectingEntityId: {
      type: [String, Number],
      required: true,
    },

    resourceName: {
      type: String,
      required: true,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    type: {
      type: String,
      default: null,
    },

    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      Schema,
      formId: 'crmRelationshipForm',
      saving: false,
      loading: true,
      errors: [],
      options: [],
      resource: this.$lodash.cloneDeep(defaultResource),
      controls: this.$lodash.cloneDeep(defaultControls),

      crmEntityOptions: [],
      crmEntityColumns: [],
      searching: false,
      hierachyTypeOptions: [],
    }
  },

  computed: {
    titleAction() {
      return this.resourceId ? 'Edit' : 'Add New'
    },
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()

          if (this.resourceId) this.fetchData()
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.getRelationships()
    if (this.type === 'company') {
      this.getAllHierarchyOption()
    }
  },

  methods: {
    close() {
      this.$emit('close')
      this.reset()
    },

    async getAllHierarchyOption() {
      const { response } = await this.$async(crmRelationshipHierarchyService.getAllByTableName(this.type))
      this.hierachyTypeOptions = formatAsHierarchyOptions(response.data)
    },

    async getRelationships() {
      const { response } = await this.$async(crmRelationshipMappingService.list({ q: this.type }))
      this.options = formatAsRelationshipOptions(response.data)
    },

    async fetchData() {
      this.controls = {
        entity1CrmRelationshipUuidKey: { value: this.data.e1CrmRelationshipUuid, label: this.data.e1CrmRelationshipName },
        connectedEntity: { value: this.data.e2Uuid, label: this.data.e2Name },
        entity2CrmRelationshipUuidKey: { value: this.data.e2CrmRelationshipUuid, label: this.data.e2CrmRelationshipName },
        mappingUuid: this.data.mappingUuid,
      }
      if (this.type === 'company') {
        this.controls.entity1HierarchyType = { value: this.data.e1HierarchyType, label: this.hierachyTypeOptions.find(x => x.value === this.data.e1HierarchyType).label }
      }
    },

    handleSearch(query) {
      this.doDebounce('timeout', async () => {
        query = query.trim()
        if (query.length < 3) {
          this.crmEntityOptions = []
          return false
        }

        this.searching = true
        const { response } = await this.$async(crmRelationshipService.autocomplete(this.type, this.connectingEntityId, { query }))

        this.crmEntityOptions = response?.data || []
        this.searching = false
      })
    },

    reset() {
      this.errors = []
      this.resource = this.$lodash.cloneDeep(defaultResource)
      this.controls = this.$lodash.cloneDeep(defaultControls)
      this.loading = false
      this.saving = false
    },

    async save() {
      this.saving = true
      const resource = {
        entity1CrmRelationshipUuidKey: this.controls.entity1CrmRelationshipUuidKey?.value,
        entity1UuidKey: this.connectingEntityId,
        entity1Table: this.type,
        entity1HierarchyType: this.controls.entity1HierarchyType?.value,
        entity2CrmRelationshipUuidKey: this.controls.entity2CrmRelationshipUuidKey?.value,
        entity2UuidKey: this.controls.connectedEntity?.value,
        entity2Table: this.type,
      }

      this.errors = await this.yupValidate(crmRelationshipMappingSchema, resource)

      if (this.errors.length) {
        this.saving = false
        return
      }

      const payload = {
        crmType: this.type,
        ...formatAsPayloadForMapping(this.connectingEntityId, this.controls.connectedEntity?.value, this.controls.relationship?.side),
      }
      const request = this.resourceId
        ? crmRelationshipMappingService.updateLink(this.controls.mappingUuid, resource)
        : crmRelationshipMappingService.link(this.controls.relationship?.value, resource)

      const { error } = await this.$async(request)

      // await this.$async(
      //   crmRelationshipMappingService.link(
      //     this.controls.relationship?.value,
      //     resource,
      //   ),
      // )

      this.saving = false
      this.$emit('saved')
      this.close()
    },
  },
}
</script>

<style lang="scss">
#crmRelationshipForm {
  .v-select .vs__dropdown-toggle {
    border-left: 1px solid var(--colour--input-border);
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
}
</style>
