<template>
  <!--   <b-card>-->
  <div>
    <b-row>
      <b-col cols="1" />
      <b-col cols="3">
        <v-select
          v-model="dashboardUuid"
          :options="options"
          :placeholder="$t('Select Dashboard')"
          label="label"
          :reduce="e=>e.value"
          :loading="optionsLoading"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <company-contact-dashboard
          :dashboard-uuid="dashboardUuid"
        />
      </b-col>
    </b-row>
  </div>
<!--   </b-card>-->
</template>

<script>
import vSelect from 'vue-select'
import { dashboardService } from '@/services/dashboard'
import CompanyContactDashboard from './CompanyContactDashboard.vue'

export default {
   components: {
      vSelect,
      CompanyContactDashboard,
   },

   data() {
      return {
         options: [],
         dashboardUuid: '',
         optionsLoading: false,
      }
   },

   watch: {
      '$route.params': {
         async handler(value) {
            if (value?.tab === 'dashboard') {
               this.optionsLoading = true
               let result
               if (this.$route.name === 'company-view') {
                  result = await dashboardService('dashboard').getMyCategorizedDashboardCompany()
               } else {
                  result = await dashboardService('dashboard').getMyCategorizedDashboardContact()
               }
               const { data } = result
               if (data && Array.isArray(data)) {
                  this.options = data.map(e => ({ value: e.uuidKey, label: e.name }))
               }
               this.optionsLoading = false
            }
         },
         immediate: true,
      },
   },
}

</script>

<style>
</style>
