<template>
  <div class="my-1">
    <feather-icon icon="InfoIcon" class="text-warning" />
    <span class="tw-mx-1 tw-text-body">
      {{ $t('full-edit-1') }}
      <router-link :to="resourceLink">
        {{ $t('full-edit-2') }}
      </router-link>
      {{ $t('full-edit-3') }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    resourceLink: {
      type: String,
      required: true,
    },
  },
}
</script>
