<template>
  <v-select
    v-model="nativeValue"
    class="entity__dropdown-search"
    :data-hide-options="searchKey.length < 2 && !options.length"
    :options="formattedOptions"
    :placeholder="placeholder"
    :loading="loading"
    @search="handleSearch"
    @input="e => $emit('input', e)"
    @clear="$emit('input', null)"
  >
    <template #selected-option-container="{ option }">
      <div class="vs__selected">{{ option.label }}</div>
    </template>
    <template slot="no-options">
      {{ $t('Sorry, no matching options') }}
    </template>
    <template
      v-if="entityType === Entity.CONTACT"
      #option="option"
    >
      <div class="tw-px-1 d-flex flex-column">
        <span>{{ option.label }}</span>
        <span
          v-if="option.meta.email"
          class="tw-text-2xs"
        >
          {{ option.meta.email }}
        </span>
        <span
          v-if="option.meta.connectedCompanies.length"
          class="tw-text-2xs"
        >
          {{ option.meta.connectedCompanies.map(({ name }) => name).join(', ') }}
        </span>
      </div>
    </template>
    <template
      v-else-if="entityType === Entity.COMPANY"
      #option="option"
    >
      <div class="tw-px-1 d-flex flex-column">
        <span>{{ option.label }}</span>
        <span
          v-if="option.meta.companyNumber"
          class="tw-text-2xs"
        >
          {{ option.meta.companyNumber }}
        </span>
        <span
          v-if="option.meta.completeAddress"
          class="tw-text-2xs"
        >
          {{ option.meta.completeAddress }}
        </span>
      </div>
    </template>
    <template
      v-else
      #option="option"
    >
      <div class="tw-px-1 d-flex flex-column">
        <span>{{ option.label }}</span>
        <span
          v-for="(item, i) in option.searchableFields"
          :key="i"
          class="tw-text-2xs"
        >
          {{ item }}
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { Entity, Schema } from '@/constants/app'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },

    loading: Boolean,

    options: {
      type: Array,
      default: () => [],
    },

    placeholder: {
      type: String,
      default: 'Search for an Entity',
    },

    entityType: {
      type: String,
      default: null,
    },

    schema: {
      type: String,
      default: null,
    },

    columns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      nativeValue: null,
      Entity,
      searchKey: '',
    }
  },

  computed: {
    formattedOptions() {
      if (this.schema === Schema.CUSTOM) {
        return this.options.map(option => {
          let label = ''
          const searchableFields = []

          Object.keys(option).forEach(key => {
            if (this.titleFields.includes(key)) {
              label += `${option[key]} `
            }

            if (this.searchableFields.includes(key)) {
              searchableFields.push(option[key])
            }
          })

          return {
            label: label.trim(),
            value: option.uuid_key,
            searchableFields,
          }
        })
      }

      if (this.entityType === Entity.CONTACT) {
        return this.options.map(option => ({
          label: `${option.firstName} ${option.lastName}`,
          value: option.uuid,
          meta: option,
        }))
      }

      if (this.entityType === Entity.COMPANY) {
        return this.options.map(option => ({
          label: option.name,
          value: option.uuid,
          meta: option,
        }))
      }

      return this.options
    },

    searchableFields() {
      return this.columns.filter(({ partOfName, searchable }) => !partOfName && searchable).map(({ key }) => key)
    },

    titleFields() {
      return this.columns.filter(({ partOfName }) => partOfName).map(({ key }) => key)
    },
  },

  watch: {
    value: {
      handler(value) {
        this.nativeValue = value
      },

      immediate: true,
    },
  },

  methods: {
    handleSearch(e) {
      this.searchKey = e
      this.$emit('search', e)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@/assets/scss/variables/variables';

.entity__dropdown-search {
  .vs__dropdown-menu {
    margin: 0 !important;
    position: absolute;
    transform: translateY(-10px);
  }

  .vs__dropdown-toggle {
    border-radius: 0.357rem !important;
  }

  .vs__open-indicator {
    display: none !important;
  }

  .vs__spinner {
    border-color: gray;
    border-left-color: white;
    border-width: 1.5px;
    width: 18px;
    height: 18px;
  }

  &[data-hide-options='true'] ul[role='listbox'] {
    display: none !important;
  }

  &.vs--open [role='combobox'] {
    border: 1px solid var(--colour--primary) !important;
  }

  .vs__dropdown-option--highlight {
    color: $white !important;
    background-color: var(--colour--primary) !important;
  }

  ul[role='listbox'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
