<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t(label)"
      :loading="loading"
      :can-handle-new-item="false"
      :options="items"
      :error="error"
      :hint="hint"
      icon="SearchIcon"
      :disabled="disabled"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import { customEntityRowsService } from '@/services/customEntityData'

export default {
  name: 'CustomEntitySearch',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    originalEntityKey: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },

    isMainEntity: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      items: [],
      loading: false,
    }
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.items = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.items = []
    },
    handleSearch(search) {
      search = search.trim()
      if (search === '' || search.length < 3) return
      this.loading = true

      customEntityRowsService(this.originalEntityKey)
        .autocomplete({
          schema: this.entity.schema,
          relatedTable: this.entity.key,
          payload: search,
        })
        .then(res => {
          this.items = res.data.map(item => ({
            label: item.n || item.name || item.uuid_key,
            value: {
              // relationshipId: this.isMainEntity ? this.id : undefined,
              schema: this.entity.schema,
              tableName: this.entity.key,
              uuidKey: item.uuid_key,
            },
          }))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
