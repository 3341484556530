import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/crm/contact'

export default {
  ...apiHelper(API_URL),
  getDataFeed: (query = { page: 0 }) => API.get(`/api/data_feed/crm/contact?${toQuery(query)}`),
  autocomplete: search => API.get(`${API_URL}/autocomplete?query=${search}`),
  getCustomFields() {
    return API.get(`${API_URL}/custom_fields`)
  },
  getHistory(id, payload = { size: 25 }) {
    return API.get(`${API_URL}/${id}/history?${toQuery(payload)}`)
  },
  getCompanies(contactId, query = {}) {
    return API.get(`${API_URL}/${contactId}/company?${toQuery(query)}`)
  },
}
