import { Colours } from '@/constants/widgets'
import { dashboardService } from '@/services/dashboard'
import RootEvents from '@/constants/rootEvents'

export default {
  computed: {

    finalizeData() {
      return this.isPreviewing
        ? {
          ...this.dataCollection,
          datasets:
            this.dataCollection.datasets?.map(data => {
              Colours.every(_color => {
                const random = Math.floor(Math.random() * 10)
                const color = Colours[random]

                const isExistingColor = this.dataCollection.datasets.some(
                  _data => _data.borderColor === color,
                )
                if (isExistingColor) return false

                data.borderColor = process.env[Colours[random]]
                data.backgroundColor = process.env[Colours[random]]
                return true
              })
              return data
            }) ?? null,
        }
        : null
    },
  },
}

export const dataTable = {
  methods: {
    async getDataSource() {
      const { response } = await this.$async(
        dashboardService('datasource').getOne(this.form.dataSource?.uuidKey),
      )

      const columnTypes = await this.$async(
        dashboardService().dataSourceFields(this.form.dataSource?.uuidKey),
      )

      this.$root.$emit(RootEvents.WIDGET_INITIATE_DATASOURCE_TABLE, {
        response,
        columnTypes,
      })
    },
  },
}

export const graphMixin = {
  methods: {
    async getData() {
      const result = this.chartData.result
      this.dataCollection = {
        labels: result[0].rowItems.map(
          item => item[Object.keys(item)[Object.keys(item).length - 1]],
        ),
        datasets: this.dataSets(),
      }
    },

    dataSets() {
      return this.chartData.result.map(res => {
        const form = {
          label: res.description,
          borderColor: null,
          tension: 0,
          backgroundColor: null,
          data: res.rowItems.map(rowItem => rowItem[Object.keys(rowItem)[0]]),
        }

        if (this.selectedWidget === 'LINE' || this.chartData.type === 'LINE') {
          form.tension = 0.2
          form.radius = 7
          form.hoverRadius = 10
          form.fill = false
        }

        return form
      })
    },
  },
  computed: {
    finalizeData() {
      return {
        ...this.dataCollection,
        datasets:
          this.dataCollection?.datasets?.map((data, index) => {
            Colours.every(_color => {
              const random = Math.floor(Math.random() * 10)
              const color = Colours[random]

              const isExistingColor = this.dataCollection.datasets.some(
                _data => _data.borderColor === color,
              )
              if (isExistingColor) return false

              if (this.selectedWidget !== 'BAR' || this.chartData.type !== 'BAR') {
                data.borderColor = process.env[Colours[random]]
                data.backgroundColor = process.env[Colours[random]]
              }

              return true
            })

            if (this.selectedWidget === 'BAR' || this.chartData.type === 'BAR') {
              data.borderColor = process.env[Colours[index]]
              data.backgroundColor = process.env[Colours[index]]
            }

            return data
          }) ?? null,
      }
    },
  },
}
