<template>
  <b-modal
    v-model="open"
    class="prozess-document-preview-modal"
    hide-footer
    centered
    no-fade
    no-close-on-backdrop
    size="lg"
    :title="$lodash.get(activeDocument, 'name')"
  >
    <div v-if="activeDocument">
      <div style="position: absolute; top: 40%; left: -100px">
        <b-button
          variant="outline-primary"
          class="btn-icon rounded-circle d-flex align-items-center"
          style="height: 50px; width: 50px;"
          :disabled="activeIndex === 0"
          @click="switchPreview(-1)"
        >
          <feather-icon
            style="margin-left: 3px;"
            icon="ChevronLeftIcon"
            size="25"
          />
        </b-button>
      </div>
      <div style="position: absolute; top: 40%; right: -100px">
        <b-button
          variant="outline-primary"
          class="btn-icon rounded-circle d-flex align-items-center"
          style="height: 50px; width: 50px;"
          :disabled="activeIndex === documents.length - 1"
          @click="switchPreview(1)"
        >
          <feather-icon
            style="margin-left: 5px;"
            icon="ChevronRightIcon"
            size="25"
          />
        </b-button>
      </div>
      <object
        v-if="activeDocument.mimeType === 'application/pdf'"
        ref="attachmentElement"
        style="width: 100%; height: 100vh"
        :data="activeDocument.url"
        type="application/pdf"
      />
      <div v-else>
        <p class="text-center my-4">
          {{ $t('No preview available') }}
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      activeIndex: 0,
      documents: [],
      activeDocument: null,
    }
  },

  mounted() {
    this.registerBusEvent(
      'prozess-document-preview-modal-toggled',
      ({ open = true, documents = [] }) => {
        this.open = open
        this.activeIndex = 0
        this.documents = documents
        this.updateActiveDocument()
      },
    )
  },

  destroyed() {
    this.$root.$off('prozess-document-preview-modal-toggled')
  },

  methods: {
    switchPreview(increment) {
      this.activeIndex += increment
      this.updateActiveDocument()
    },

    updateActiveDocument() {
      this.activeDocument = this.documents[this.activeIndex]
    },
  },
}
</script>
