<template>
  <div
    class="prozess-custom-fields"
    :class="[{ 'd-flex flex-wrap': cols > 1 }, `cols--${cols}`]"
  >
    <template v-for="(customField, i) of customFields">
      <div
        :key="i"
        :class="[cols === 2 ? ((i + 1) % 2 ? 'odd' : 'even') : '']"
      >
        <div v-if="[CustomFieldTypes.Text, CustomFieldTypes.Phone, CustomFieldTypes.Url, CustomFieldTypes.Email].includes(customField.fieldType)">
          <prozess-input
            v-model="currentValue[customField.key]"
            :disabled="disabled"
            :placeholder="$t($case.title(customField.label))"
            :icon="customFieldTypeIcons[customField.fieldType]"
            :field="customField.key"
            :name="customField.key"
            :error="$hasError(errorPrefix + '.' + customField.key)"
            :hint="customField.required ? '*' + $t('Required') : ''"
            @enter="$emit('enter')"
          />
        </div>
        <div v-else-if="customField.fieldType === CustomFieldTypes.Number">
          <prozess-input
            v-model.number="currentValue[customField.key]"
            type="number"
            step="any"
            :disabled="disabled"
            :placeholder="$t($case.title(customField.label))"
            :icon="customFieldTypeIcons[customField.fieldType]"
            :field="customField.key"
            :name="customField.key"
            :error="$hasError(errorPrefix + '.' + customField.key)"
            :hint="customField.required ? '*' + $t('Required') : ''"
            @wheel="$event.target.blur()"
            @enter="$emit('enter')"
          />
        </div>
        <div v-else-if="customField.fieldType === CustomFieldTypes.Percent">
          <prozess-input
            v-model="currentValue[percentPrefix + customField.key]"
            type="number"
            step="any"
            :disabled="disabled"
            :placeholder="$t($case.title(customField.label))"
            :icon="customFieldTypeIcons[customField.fieldType]"
            :field="percentPrefix + customField.key"
            :name="percentPrefix + customField.key"
            :error="$hasError(errorPrefix + '.' + percentPrefix + customField.key)"
            :hint="customField.required ? '*' + $t('Required') : ''"
            @wheel="$event.target.blur()"
            @enter="$emit('enter')"
          />
        </div>
        <div
          v-else-if="customField.fieldType === CustomFieldTypes.Bool"
          class="mb-1 mt-1"
        >
          <b-form-checkbox
            v-model="currentValue[customField.key]"
            :disabled="disabled"
            switch
            inline
            @keyup.enter.native="$emit('enter')"
          >
            <span style="font-size: 1rem">{{ $t($case.title(customField.label)) }}</span>
          </b-form-checkbox>
          <span
            v-if="$hasError(`${errorPrefix}.${customField.key}`)"
            class="invalid-feedback"
          >
            {{ $t($hasError(`${errorPrefix}.${customField.key}`)) }}
          </span>
        </div>
        <div
          v-else-if="customField.fieldType === CustomFieldTypes.Date"
          class="mb-1"
        >
          <prozess-field-wrapper
            :error="$hasError(errorPrefix + '.' + customField.key)"
            :hint="customField.required ? '*' + $t('Required') : ''"
          >
            <b-form-datepicker
              v-model="currentValue[customField.key]"
              :disabled="disabled"
              :locale="$i18n.locale"
              :placeholder="$t($case.title(customField.label))"
              :hint="customField.required ? '*' + $t('Required') : ''"
              reset-button
              @keyup.enter.native="$emit('enter')"
            />
          </prozess-field-wrapper>
        </div>
        <div
          v-else-if="customField.fieldType === CustomFieldTypes.LongText"
          class="mb-1"
        >
          <prozess-field-wrapper
            :error="$hasError(errorPrefix + '.' + customField.key)"
            :hint="customField.required ? '*' + $t('Required') : ''"
          >
            <b-form-textarea
              v-model="currentValue[customField.key]"
              :disabled="disabled"
              :placeholder="$t($case.title(customField.label))"
              rows="3"
            />
          </prozess-field-wrapper>
        </div>
        <div
          v-else-if="isTypeListLike(customField)"
          class="mb-1"
        >
          <prozess-field-wrapper
            :disabled="disabled"
            :icon="customFieldTypeIcons[customField.fieldType]"
            :error="$hasError(errorPrefix + '.' + customField.key)"
            :hint="customField.required ? '*' + $t('Required') : ''"
          >
            <prozess-select
              v-model="currentValue[customField.key]"
              style="flex: 1"
              :multiple="isMultiSelect(customField)"
              :disabled="disabled"
              :placeholder="$t($case.title(customField.label))"
              :options="customField.values"
              :label="$t($case.title(customField.label))"
              @keyup.enter.native="$emit('enter')"
            />
          </prozess-field-wrapper>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CustomFieldTypes, { customFieldTypeIcons } from '@/constants/customFieldTypes'
import { advancedFieldValue } from '@/helpers/customFields'
import ProzessInput from './ProzessInput.vue'
import ProzessSelect from './ProzessSelect.vue'
import ProzessFieldWrapper from './ProzessFieldWrapper.vue'

export default {
  components: {
    ProzessInput,
    ProzessSelect,
    ProzessFieldWrapper,
  },
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: null,
    },
    customFields: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    errorPrefix: {
      type: String,
      default: 'customFields',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      customFieldTypeIcons,
      CustomFieldTypes,
      currentValue: null,
      loaded: false,
      percentPrefix: '___',
    }
  },
  watch: {
    value: {
      handler() {
        if (this.loaded === false) {
          // Load custom field type percentage
          this.customFields.forEach(customField => {
            let data = this.value[customField.key]
            const isCreateForm = typeof data === 'undefined'
            if (isCreateForm) {
              data = customField.defaultValue
            }

            if (customField.fieldType === CustomFieldTypes.Percent) {
              if (!isCreateForm) {
                data = this.$round10(this.value[customField.key] * 100, -4)
              }
              this.$set(this.value, `${this.percentPrefix}${customField.key}`, data)
            }

            if (customField.fieldType === CustomFieldTypes.Bool) {
              if (isCreateForm) {
                this.$set(this.value, customField.key, false)
              }
            } else {
              this.$set(this.value, customField.key, data)
            }
          })
          this.loaded = true
        }

        Object.keys(this.value).forEach(key => {
          let content = this.value[key]

          if (!!content && typeof content === 'string') {
            content = content.trim()

            /**
             * If the value is a string in this format: [Option 1, Option 2],
             * that means the value is a Multi-Select value.
             * We will then convert it into a valid Array.
             */
            content = advancedFieldValue(content, { toArrayIfMulti: true })

            /**
             * If we were able to convert the value into an array,
             * we will then set it as the value of that Custom Field.
             */
            if (Array.isArray(content)) {
              this.value[key] = content
            }
          }
          if (!key.includes(this.percentPrefix) && content === '') {
            this.value[key] = null
          }
          if (key.includes(this.percentPrefix)) {
            const originalKey = key.split(this.percentPrefix).join('')
            if (content === '') {
              this.value[originalKey] = null
              delete this.value[key]
            }
            if (content) {
              this.$set(this.value, originalKey, this.value[key] / 100)
            }
          }
          if (!this.checkBool(content)) {
            if (Array.isArray(content)) {
              const value = content && content.length ? content : null
              this.value[key] = value
            }
          }
        })

        this.currentValue = this.value
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', this.currentValue)
    },

    isTypeListLike(customField) {
      return [CustomFieldTypes.List, CustomFieldTypes.MultiSelect].includes(customField?.fieldType)
    },

    isMultiSelect(customField) {
      return customField?.fieldType === CustomFieldTypes.MultiSelect
    },
    checkBool(bool) {
      return typeof bool === 'boolean' || (typeof bool === 'object' && bool !== null && typeof bool.valueOf() === 'boolean')
    },
  },
}
</script>
<style scoped lang="scss">
.prozess-custom-fields {
  &.cols--2 {
    .even {
      width: 50%;
      padding-left: 4px;
    }

    .odd {
      width: 50%;
      padding-right: 4px;
    }
  }
}
</style>
