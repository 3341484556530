import { GenericTabs } from '@/constants/app'
import { getMainSourceTable } from '@/helpers/customEntity'

export default {
  computed: {
    canGoToMainEntity() {
      return this.$can('Read', this.mainSourceTable)
    },

    mainSourceTable() {
      return getMainSourceTable(this.customEntity)
    },
  },

  methods: {
    goToMainEntity(item) {
      if (!this.canGoToMainEntity) {
        return false
      }

      this.$router.push({
        name: 'custom-entity-data-view',
        params: {
          key: this.mainSourceTable,
          id: item.uuid_key,
          tab: GenericTabs.OVERVIEW_360,
        },
      })
    },
  },
}
