<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t(placeholder)"
      :loading="loading"
      :new-item-message="$t('Add as new company')"
      :can-handle-new-item="canHandleNewItem"
      :options="items"
      :icon="icon"
      :error="error"
      :hint="hint"
      :disabled="disabled"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import companyService from '@/services/company'

export default {
  props: {
    icon: {
      type: String,
      default: 'UserIcon',
    },
    autocompleteApi: {
      type: Function,
      default: search => companyService.autocomplete(search.trim()),
    },
    canHandleNewItem: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      default: 'Name',
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      items: [],
      contactSearch: '',
      loading: false,
    }
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.items = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.items = []
    },
    async handleSearch(search) {
      if (search.trim() === '') return
      this.loading = true
      const { response } = await this.$async(this.autocompleteApi != null ? this.autocompleteApi(search) : companyService.autocomplete(search.trim()))
      this.items = response?.data.map(item => ({
        label: item.name,
        selectedLabel: item.name,
        additionalLabels: ['completeAddress'],
        value: item,
      }))
      this.loading = false
    },
  },
}
</script>
