<template>
  <b-modal
    id="note-view-edit-modal"
    v-model="open"
    hide-footer
    centered
    no-fade
    size="lg"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header d-flex align-items-center border-bottom pb-1">
        <feather-icon :icon="formIcon" class="mr-1" />
        <h4 class="mb-0">
          {{ $t(formTitle) }}
        </h4>
      </div>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        class="d-flex flex-column"
        variant="transparent"
        style="flex: 1"
      >
        <form
          :id="formId"
          ref="form"
          autocomplete="off"
          class="py-2"
          @submit.prevent="handlePrimaryBtnClick"
        >
          <prozess-input
            v-model="form.title"
            :disabled="mode === Mode.VIEW"
            :placeholder="$t('Title')"
            icon="TypeIcon"
            field="title"
            name="title"
            :error="$hasError('title')"
          />
          <div>
            <label class="tw-text-xs tw-font-semibold mb-0">
              {{ $t('Note') }}
            </label>
            <hr class="my-1">
            <prozess-quill-editor
              v-model="form.note"
              :disabled="mode === Mode.VIEW"
              class="mb-2"
              :placeholder="$t('Note')"
              :error="$hasError('note')"
            />
          </div>
          <b-container fluid>
            <b-row>
              <b-col
                v-for="field in filteredMetaData"
                :key="field.key"
                md="6"
                cols="12"
              >
                <div>
                  <AppDynamicField
                    v-model="form[field.key]"
                    :disabled="mode === Mode.VIEW"
                    :field="field"
                    :error="$hasError(field.key)"
                    :resourceId="resourceId"
                  />
                </div>
              </b-col>
            </b-row>
          </b-container>
          <!-- <template v-if="customFields.length">
            <h4 class="mb-2">
              {{ $t('Custom Fields') }}
            </h4>
            <div>
              <prozess-custom-fields
                v-model="resource.customFields"
                :cols="2"
                :disabled="mode === Mode.VIEW"
                :custom-fields="customFields"
                :errors="errors"
              />
            </div>
          </template> -->
          <div class="d-flex justify-content-end align-items-center tw-mt-10">
            <button class="btn btn-dark" type="button" @click="close">
              {{ $t(secondaryBtnLabel) }}
            </button>
            <button
              class="btn btn-primary ml-1 d-flex justify-content-center align-items-center"
              type="submit"
              :form="formId"
              :title="
                !canUpdate
                  ? $t('You dont have permission to update note')
                  : null
              "
              :disabled="saving || !canUpdate"
            >
              <b-spinner v-if="saving" small class="mr-1" />
              <span>{{ $t(primaryBtnLabel) }}</span>
            </button>
          </div>
        </form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { markupToText } from '@/helpers/app'
import { noteSchema } from '@/schema/note'
import { Schema } from '@/constants/app'
import noteService from '@/services/note'
import ProzessQuillEditor from '@core/components/ProzessQuillEditor.vue'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import fieldMixins, { customFieldsMixins } from '@/mixins/fields'
import { fillableOnly } from '@/helpers/field'
import advancedFieldMgmt from '@/services/advancedFieldMgmt'

const Mode = {
  VIEW: 'view',
  EDIT: 'edit',
}

const staticField = {
  title: 'title',
  note: 'note',
}
export default {
  components: {
    ProzessQuillEditor,
    AppDynamicField,
  },

  mixins: [fieldMixins, customFieldsMixins],

  data() {
    return {
      Mode,
      open: false,
      errors: [],
      loading: false,
      saving: false,
      mode: Mode.VIEW,
      entityType: '',
      entityId: '',
      schemaName: '',
      version: '',
      resourceId: '',
      serviceName: noteService,
      formId: 'noteViewEditForm',
      form: {},
      fieldMetadata: [],
      customFields: [],
    }
  },

  computed: {
    filteredMetaData() {
      return this.fieldMetadata.filter(
        fieldFilter => !Object.values(staticField).some(val => val === fieldFilter.key),
      )
    },
    formIcon() {
      return this.mode === Mode.VIEW ? 'ClipboardIcon' : 'EditIcon'
    },

    formTitle() {
      return this.mode === Mode.VIEW ? 'Note Details' : 'Edit Note'
    },

    primaryBtnLabel() {
      return this.mode === Mode.VIEW ? 'Edit' : 'Save Changes'
    },

    secondaryBtnLabel() {
      return this.mode === Mode.VIEW ? 'Dismiss' : 'Cancel'
    },

    canUpdate() {
      if (this.schemaName === Schema.CUSTOM) {
        return this.$can('Update', this.entityType)
      }

      switch (this.entityType) {
        case 'inquiry':
          return this.$can('Update', 'Inquiry')

        default:
          return this.$can('Update', 'CRM')
      }
    },
  },

  mounted() {
    this.registerBusEvent('note-edit-view-modal-toggled', data => {
      this.open = data.open || true
      this.mode = data.mode || Mode.VIEW
      this.entityType = data.entityType
      this.entityId = data.entityId
      this.schemaName = data.schemaName
      this.resourceId = data.note.uuid

      this.reset()
      this.getInitialData()
    })
  },

  destroyed() {
    this.$root.$off('note-edit-view-modal-toggled')
  },

  methods: {
    close() {
      this.open = false
    },

    async getInitialData() {
      this.loading = true
      await this.$async(this.getOne(this.resourceId))
      this.loading = false
    },

    async getOne(id) {
      // Start: setting up of AFM
      const { response: responseAFM } = await this.$async(
        advancedFieldMgmt.getFieldsByEntity({
          schema: 'notes',
          table: 'note',
        }),
      )

      const fieldMetadata = fillableOnly(responseAFM.data)

      fieldMetadata.forEach(field => {
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      this.fieldMetadata = this.$lodash.cloneDeep(fieldMetadata)

      // End: setting up of AFMM
      const request = noteService.getOneByEntity(
        this.schemaName,
        this.entityType,
        this.entityId,
        id,
      )

      const { response } = await this.$async(request)
      const { data } = response

      this.version = data.noteVersion
      this.formatAsFormData(data)
    },

    handlePrimaryBtnClick() {
      this.mode === Mode.VIEW ? (this.mode = Mode.EDIT) : this.save()
    },

    reset() {
      this.errors = []
      this.form = {}
      this.fieldMetadata = []
      this.loading = false
      this.saving = false
    },

    async save() {
      this.form.title = this.form.title.trim()
      const staticFieldValidations = [
        {
          required: true,
          key: 'title',
          type: 'TEXT',
        },
        {
          required: true,
          key: 'note',
          type: 'LONGTEXT',
        },
      ]
      this.errors = await this.yupValidate(
        noteSchema([...this.fieldMetadata, ...staticFieldValidations]),
        { ...this.form, note: markupToText(this.form.note)?.trim() },
      )

      if (!this.errors.length) {
        this.saving = true
        const payload = {
          noteVersion: this.resourceId ? this.version : undefined,
          note: this.form.note,
          ...this.formatAsPayload(),
        }
        const { errors } = await this.$async(
          noteService.updateByEntity(
            this.schemaName,
            this.entityType,
            this.entityId,
            this.resourceId,
            payload,
          ),
        )
        if (errors) {
          this.errors = this.$loadErrors(errors)
        } else {
          this.$emit('saved')
          this.close()
        }

        this.saving = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/master-variables.scss';

#note-view-edit-modal {
  .modal-header {
    display: none;
  }

  .modal-body {
    padding: 0;
  }

  .form-sidebar {
    height: auto;
  }

  .modal__body {
    background: var(--colour--sidebar-form-bg);
  }

  .modal-body,
  .modal__body,
  .modal-content {
    border-radius: 1rem;
  }
}
</style>
