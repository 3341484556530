import { renameKeys } from 'lodash-contrib'
import { cloneDeep, pick, invert } from 'lodash'

export const standardKeyMapping = {
  firstName: 'first_name',
  statusId: 'statusId',
  lastName: 'last_name',
  emailAddress: 'email_address',
  mobileNumber: 'mobile_number',
  title: 'title',
  address: 'address',
  city: 'city',
  country: 'country',
  zip: 'zip',
  birthDate: 'birth_date',
  description: 'description',
  contactVersion: 'contactVersion',
}

export const formatAsPayload = (form, fieldMetadata) => {
  form = cloneDeep(form)

  const standardFields = pick(
    form,
    Object.values(standardKeyMapping),
  )

  return {
    ...renameKeys(standardFields, invert(standardKeyMapping)),

    customFields: fieldMetadata.reduce((acc, field) => {
      if (field.customField) {
        acc[field.key] = field.type === 'DATE' && !form[field.key] ? null : form[field.key]
      }

      return acc
    }, {}),
  }
}
