export const aggregates = [
  {
    label: 'Sum',
    key: 'SUM',
  },

  {
    label: 'Average',
    key: 'AVG',
  },

  {
    label: 'Max',
    key: 'MAX',
  },

  {
    label: 'Min',
    key: 'MIN',
  },

  {
    label: 'Count',
    key: 'COUNT',
  },
]

export const Colours = [
  'VUE_APP_COLOR_CHART_1',
  'VUE_APP_COLOR_CHART_2', 'VUE_APP_COLOR_CHART_3',
  'VUE_APP_COLOR_CHART_4', 'VUE_APP_COLOR_CHART_5',
  'VUE_APP_COLOR_CHART_6', 'VUE_APP_COLOR_CHART_7',
  'VUE_APP_COLOR_CHART_8',
]

export const filterInitialForm = {
  value: '',
  condition: {
    label: '',
    value: '',
  },
  userAccessible: false,
  selectedUser: { label: '' },
}

export const widgetTypes = [
  {
    label: 'KPI',
    value: 'KPI',
    icon: 'CrosshairIcon',
  },
  {
    label: 'Table',
    value: 'TABLE',
    icon: 'ColumnsIcon',
  },

  {
    label: 'Bar graph',
    value: 'BAR',
    icon: 'BarChart2Icon',
  },

  {
    label: 'Line graph',
    value: 'LINE',
    icon: 'ActivityIcon',
  },

  {
    label: 'Pie Chart',
    value: 'PIE',
    icon: 'PieChartIcon',
  },

  {
    label: 'Treemap Chart',
    value: 'TREE',
    icon: 'LayoutIcon',
  },

]
