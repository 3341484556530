<template>
  <apex-chart
    type="pie"
    :series="series"
    :options="chartOptions"
    height="100%"
  />
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { mixins } from 'vue-chartjs'

const { reactiveProp } = mixins
export default {
  components: {
    ApexChart,
  },
  mixins: [reactiveProp],
  data() {
    return {
      series: [],
      chartOptions: {
        labels: [],
        legend: {
          labels: {
            colors: '#777777',
          },
        },
      },
    }
  },
  watch: {
    chartData: {
      handler(newVal) {
        if (newVal.datasets && newVal.datasets.length) {
          this.series.length = 0
          this.chartOptions.labels.length = 0
          for (let i = 0; i < newVal.datasets[0].data.length; i++) {
            this.series.push(newVal.datasets[0].data[i])
            const elementLabel = newVal.labels[i] != null ? newVal.labels[i] : 'NO_VALUE'
            this.chartOptions.labels.push(elementLabel)
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style>
</style>
