<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="editing"
    :loading="loading"
    :is-view-only="false"
    :saving-disabled="false"
    :saving="loading"
    form-title="Aggregation"
    form-icon="GitPullRequestIcon"
    save-label="Apply"
    @submit="$emit('save', selectedAggregates, isGroupBySelected)"
    @close="close"
  >
    <form class="tw-px-6" autocomplete="off" style="flex: 1" @submit.prevent>
      <div style="margin-top: 50px;">
        <div class="checkbox-list-container tw-mt-4">
          <div class=" checkbox-list-container__header">
            <span>{{ $t('Aggregated by') }}</span>
          </div>

          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="checkbox-group-1"
              :disabled="viewing"
              :aria-describedby="ariaDescribedby"
              class="checkbox-list-container__checkbox-container"
            >
              <b-form-radio
                v-for="aggregate in aggregatesFilter"
                :key="aggregate.key"
                v-model="selectedAggregates"
                class="tw-my-2 "
                :value="aggregate"
              >{{ $t(aggregate.label) }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <b-form-checkbox v-model="isGroupBySelected" class="tw-mt-4" switch>
          <span class="tw-text-xs">{{ $t('Group by') }}</span>
        </b-form-checkbox>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import ProzessSidebarModalWrapper from '@/@core/components/ProzessSidebarModalWrapper.vue'
import { aggregates } from '@/constants/widgets'
import RootEvents from '@/constants/rootEvents'

export default {
  components: { ProzessSidebarModalWrapper },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    viewing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      aggregates,
      type: null,
      loading: false,
      editing: true,
      isGroupBySelected: false,
      selectedAggregates: null,
    }
  },
  computed: {
    aggregatesFilter() {
      return this.type !== 'NUMBER'
        ? this.aggregates.filter(aggregate => aggregate.key === 'COUNT')
        : this.aggregates
    },
  },

  methods: {
    setData({ field }) {
      this.selectedAggregates = field.selectedAggregates
      this.isGroupBySelected = field.isGroupBy
      this.type = field.type
    },

    close() {
      this.$root.$emit(RootEvents.BASIC_ASSIGN_VALUE, {
        name: 'showAggregationForm',
        value: false,
      })
    },
  },
}
</script>
