<template>
  <div
    class="prozess-quill-editor"
    :class="{ 'pz-ql-editor--disabled': disabled }"
  >
    <quill-editor
      v-if="visible"
      v-model="nativeValue"
      :disabled="disabled"
      :options="defaultOptions"
      @input="$emit('input', nativeValue)"
    />
    <span
      v-show="error"
      class="invalid-feedback"
      style="flex: 1"
    >
      {{ $t(error) }}
    </span>
  </div>
</template>

<script>
import { quillEditor as QuillEditor } from 'vue-quill-editor'
import '@core/scss/vue/libs/quill.scss'

export default {
  components: {
    QuillEditor,
  },

  props: {
    value: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    options: {
      type: Object,
      default: () => ({}),
    },

    error: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      nativeValue: '',
      visible: true,
    }
  },

  computed: {
    defaultOptions() {
      const modules = this.options.modules || {
        toolbar: [['bold', 'italic', 'underline'], [{ list: 'bullet' }, { list: 'ordered' }], [{ align: [] }], ['clean']],
      }

      return {
        placeholder: this.placeholder,
        ...this.options,
        modules,
        formats: [
          'background',
          'bold',
          'color',
          'font',
          'code',
          'italic',
          'link',
          'size',
          'strike',
          'script',
          'underline',
          'blockquote',
          'header',
          'indent',
          'list',
          'align',
          'direction',
          'code-block',
          'formula',
          // 'image'
          // 'video'
        ],
      }
    },
  },

  watch: {
    defaultOptions: {
      immediate: true,

      handler() {
        this.visible = false
        this.$tick(() => {
          this.visible = true
        })
      },
    },

    value: {
      handler(value) {
        this.nativeValue = value
      },

      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.ql-editor {
  height: 250px;
  color: var(--colour--input-text);
  p {
    line-height: 1.42;
  }
}

.pz-ql-editor--disabled {
  .ql-toolbar {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
