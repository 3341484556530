import { stringify } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'
import API from '@axios'

const API_URL = '/api/data'

export default {
  ...apiHelper(API_URL),

  attach: ({
    owningSchema,
    owningEntityType,
    owningEntityId,
    relatedSchema,
    relatedEntityType,
    relatedEntityId,
  }, query = {}) => API.put(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/attach/${relatedSchema}/${relatedEntityType}/${relatedEntityId}?${stringify(query)}`),

  autoComplete: ({
    owningSchema,
    owningEntityType,
    relatedSchema,
    relatedEntityType,
  }, query = { query: '' }) => API.get(`${API_URL}/from/${owningSchema}/${owningEntityType}/autocomplete/${relatedSchema}/${relatedEntityType}?${stringify(query)}`),

  createRelatedEntityData: ({
    owningSchema,
    owningEntityType,
    owningEntityId,
    relatedSchema,
    relatedEntityType,
  },
  payload = {}) => API.post(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/row_of/${relatedSchema}/${relatedEntityType}`, payload),

  updateRelatedEntityData: ({
    owningSchema,
    owningEntityType,
    owningEntityId,
    relatedSchema,
    relatedEntityType,
    relatedEntityId,
  },
  payload = {}) => API.patch(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/row_of/${relatedSchema}/${relatedEntityType}/${relatedEntityId}`, payload),

  getAllRelatedEntityData: ({
    owningSchema,
    owningEntityType,
    owningEntityId,
    relatedSchema,
    relatedEntityType,
  },
  query = { size: 25 }) => API.get(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/row_of/${relatedSchema}/${relatedEntityType}?${stringify(query)}`),

  getOneRelatedEntityData: ({
    owningSchema,
    owningEntityType,
    owningEntityId,
    relatedSchema,
    relatedEntityType,
    relatedEntityId,
  },
  query = { size: 25 }) => API.get(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/row_of/${relatedSchema}/${relatedEntityType}/${relatedEntityId}?${stringify(query)}`),

  detachOrDelete: (type, {
    owningSchema,
    owningEntityType,
    owningEntityId,
    relatedSchema,
    relatedEntityType,
    relatedEntityId,
  }, query = {}) => {
    if (type === 'detach') {
      return API.put(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/detach/${relatedSchema}/${relatedEntityType}/${relatedEntityId}?${stringify(query)}`)
    }

    if (type === 'delete') {
      return API.delete(`${API_URL}/from/${owningSchema}/${owningEntityType}/${owningEntityId}/row_of/${relatedSchema}/${relatedEntityType}/${relatedEntityId}?${stringify(query)}`)
    }

    return Promise.reject()
  },
}
