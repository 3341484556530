import advancedFieldMgmtService from '@/services/advancedFieldMgmt'

export default ({ schemaKey, tableKey, fieldKeyName = 'key' }) => ({
  data() {
    return {
      queryFields: [],
      dynamicTableKey: null,
    }
  },

  computed: {
    instanceId() {
      return this.$route.params.id
    },
  },

  methods: {
    async prefillQueryFields() {
      if (this.dynamicTableKey) {
        tableKey = this.dynamicTableKey
      }
      const fieldKeys = this.queryFields.map(e => e[fieldKeyName])
      const { response, error } = await this.$async(
        advancedFieldMgmtService.executeQueries(
          {
            schema: schemaKey,
            table: tableKey,
            fieldKeys,
          },
          { id: this.instanceId },
        ),
      )
      if (response && response.data) {
        for (const fieldKey of fieldKeys) {
          if (Object.hasOwn(response.data, fieldKey)) {
            const fieldData = response.data[fieldKey]
            let data =
              Array.isArray(fieldData) && fieldData.length > 1
                ? fieldData.map(item => {
                  const fullName = `${item.first_name} ${item.last_name}`
                  return `${fullName}, ${item.email}`
                })
                : Object.values(fieldData[0] || {}).join(', ')

            if (Array.isArray(fieldData) && fieldData.length > 1) {
              data = data.join('</br>')
            }
            this.$set(this.resource.customFields, fieldKey, fieldData === 'query failed' ? this.$i18n.t('Query failed!') : data)
            if (this.dynamicTableKey) {
              const obj = this.resource.data.find(d => d.key === fieldKey)
              if (obj) {
                this.$set(obj, 'value', fieldData === 'query failed' ? this.$i18n.t('Query failed!') : data)
                this.resource.values[fieldKey] = fieldData === 'query failed' ? this.$i18n.t('Query failed!') : data
                // rerender the fieldsManagement
                this.fieldsManagement.push()
              }
            }
          } else {
            this.$set(this.resource.customFields, fieldKey, this.$i18n.t('Query failed!'))
            if (this.dynamicTableKey) {
              const obj = this.resource.data.find(d => d.key === fieldKey)
              if (obj) {
                this.$set(obj, 'value', this.$i18n.t('Query failed!'))
                this.resource.values[fieldKey] = this.$i18n.t('Query failed!')
                // rerender the fieldsManagement
                this.fieldsManagement.push()
              }
            }
          }
        }
      } else {
        for (const fieldKey of fieldKeys) {
          this.$set(this.resource.customFields, fieldKey, this.$i18n.t('Query failed!'))
          if (this.dynamicTableKey) {
            const obj = this.resource.data.find(d => d.key === fieldKey)
            if (obj) {
              this.$set(obj, 'value', this.$i18n.t('Query failed!'))
              this.resource.values[fieldKey] = this.$i18n.t('Query failed!')
              // rerender the fieldsManagement
              this.fieldsManagement.push()
            }
          }
        }
      }
    },
  },
})
