import * as yup from 'yup'
import { customFieldsSchema } from '@/schema/customFields'
import dayjs from 'dayjs'
import { shapeSchemaByMetadata } from '@/helpers/field'

export const inquirySchema = shapeSchemaByMetadata
// export const inquirySchema = ({
//   $t, $d, customFields, receivedDate, contactCustomFields, isNewContact,
// }) => {
//   const shape = {
//     title: yup.string().required().max(100, 'Max 100 limit'),
//     contact: yup.string().required(),
//     statusId: yup.string().required().nullable(),
//     priorityId: yup.string().required().nullable(),
//     receivedDate: yup.date().required(),
//     dueDate: yup.date().nullable()
//       .min(
//         dayjs(receivedDate).startOf('day'),
//         $t('Must be later than or equal to received date'),
//       ),
//     customFields: customFieldsSchema(customFields),
//   }

//   if (isNewContact) {
//     shape.newContact = yup.object().shape({
//       lastName: yup.string().required().max(100, 'Max 100 limit'),
//       customFields: customFieldsSchema(contactCustomFields),
//     })
//   }

//   return yup.object().shape(shape)
// }

export default {
  inquirySchema,
}
