<template>
  <b-card
    no-body
    class="system-settings-card prozess-custom-entity-data"
  >
    <div class="m-2 custom-entity-table">
      <CRMRelationshipMappingForm
        :visible="showForm"
        :connecting-entity-id="entityId"
        :resource-id="resourceId"
        :type="type"
        :data="data"
        :resource-name="resourceName"
        @close="showForm = false"
        @saved="getAllMappings"
      />
      <div class="mb-1 d-flex table-actions">
        <b-button
          :id="`create-${type}-button`"
          variant="primary"
          class="mr-1"
          :class="{ 'icon-disabled': !canLink }"
          @click="canLink && toggleForm()"
        >
          <feather-icon
            icon="LinkIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Add Relationship') }}</span>
        </b-button>
        <b-tooltip :target="`create-${type}-button`">
          {{ canLink ? $t('Add Relationship') : $t('no-action-permission') }}
        </b-tooltip>
        <b-button
          v-if="isCompanyTable()"
          :id="`show-tree-button`"
          variant="outline-primary"
          class="mr-1"
          :disabled="this.items.length===0 && showTree === false"
          @click="toggleShowTreeRelationship()"
        >
          <feather-icon
            icon="GitPullRequestIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ this.$t(showTree ? 'Hide Tree' : 'Show Tree') }}</span>
        </b-button>
        <b-tooltip :target="`show-tree-button`">
          {{ $t(showTree ? 'Hide Tree' : 'Show Tree') }}
        </b-tooltip>
        <div v-if="showTree">
          <b-dropdown
            variant="outline-primary"
            style="min-height: 35px; width: 200px"
          >
            <template #button-content>
              <span class="text-nowrap">{{ $route.query.status == null ? $t('Select Company Status') : $t(getStatusLabel($route.query.status)) }} </span>
            </template>
            <b-dropdown-text style="width: 200px">{{ $t('Select Company Status') }}</b-dropdown-text>
            <b-dropdown-item :key="all" @click="setStatusFilter('all');"> {{ $t('All') }} </b-dropdown-item>
            <b-dropdown-item :key="active" @click="setStatusFilter('active');"> {{ $t('Active') }} </b-dropdown-item>
            <b-dropdown-item :key="inactive" @click="setStatusFilter('inactive');"> {{ $t('Inactive') }} </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-if="!showTree">
          <b-dropdown
            variant="outline-primary"
            style="min-height: 35px; width: 140px"
          >
            <template #button-content>
              <span class="text-nowrap">{{ $route.query.hierarchy == null ? $t('Filter by Hierarchy') : $t(getHierarchyLabel($route.query.hierarchy)) }} </span>
            </template>
            <b-dropdown-text style="width: 200px">{{ $t('Select Hierarchy') }}</b-dropdown-text>
            <b-dropdown-item :key="all" @click="setHierarchyFilter('ALL');"> {{ $t('All levels') }} </b-dropdown-item>
            <b-dropdown-item :key="active" @click="setHierarchyFilter('PARENT');"> {{ $t('Parent') }} </b-dropdown-item>
            <b-dropdown-item :key="inactive" @click="setHierarchyFilter('CHILD');"> {{ $t('Subsidiary') }} </b-dropdown-item>
            <b-dropdown-item :key="inactive" @click="setHierarchyFilter('SAME_LEVEL');"> {{ $t('Same level') }} </b-dropdown-item>
          </b-dropdown>
        </div>

        <b-button v-if="!showTree"
            :id="`export-table-button`"
            variant="outline-primary"
            class="mr-1"
            style="margin-left: 38px"
            @click="(!onExport && runExport())"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ this.$t('Export') }}</span>
          </b-button>
      </div>

      <b-button
        v-if="showTree"
        :id="`export-table-button`"
        @click="openModal()"
        >
          <feather-icon icon="DownloadIcon" class="mr-50"/>
          <span class="align-middle">{{ this.$t('Generate Document') }}</span>
      </b-button>
        <b-table
          v-if="!this.showTree"
          ref="refTable"
          class="table-responsive"
          :busy="loading"
          :items="items"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>
          <template #cell(e1CrmRelationshipName)="{ item }">
            <b-badge
              pill
              variant="primary"
            >
              {{ entityId === item.e1Uuid ? item.e1CrmRelationshipName : item.e2CrmRelationshipName }}
            </b-badge>
          </template>
          <template #cell(e2Name)="{ item }">
            <prozess-resource-name
              :id="`view-row-${item.crmRelationshipMappingUuidKey}-link`"
              class="text-nowrap d-inline-block"
              :has-permission="$can('Read', 'CRM')"
              :to="{
                name: `${type}-view`,
                params: {
                  id: entityId === item.e2Uuid ? item.e1Uuid : item.e2Uuid,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
              :title="entityId === item.e1Uuid ? item.e2Name : item.e1Name"
            />
          </template>
          <template #cell(e2CrmRelationshipName)="{ item }">
            <b-badge pill>
              {{ entityId === item.e1Uuid ? item.e2CrmRelationshipName : item.e1CrmRelationshipName }}
            </b-badge>
          </template>
          <template #cell(actions)="{ item }">
            <feather-icon
              :id="`edit-row-${item.uuidKey}-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !canUpdate,
              }"
              @click="canUpdate && edit(item)"
            />
            <b-tooltip
              :title="canUpdate ? $t('Edit') : $t('no-action-permission')"
              :target="`edit-row-${item.uuidKey}-icon`"
              placement="left"
            />
            <feather-icon
              :id="`delete-row-${item.uuidKey}-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !canUpdate,
              }"
              @click="canUpdate && confirmRemoval(item)"
            />
            <b-tooltip
              :title="canUpdate ? $t('Delete') : $t('no-action-permission')"
              :target="`delete-row-${item.uuidKey}-icon`"
              placement="left"
            />
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading && this.showTree === false"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handlePageChange"
      />
    <CRMRelationshipMappingTree  :visible="showTree" :requesting-node="this.$route.params.id" :status-filter="this.$route.params.status"  :crm-type="this.type" @close="showTree = false" />
    <GenerateModal
      ref="genModal"
      @submit="proceedGenerate"
      />
  </b-card>
</template>
<script>
import { Entity, GenericTabs } from '@/constants/app'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import ImexAvailableExportsDropdown from '@/views/shared/Imex/ImexAvailableExportsDropdown.vue'
import CRMRelationshipMappingForm from '@/components/CRMRelationships/CRMRelationshipMappingForm.vue'
import CRMRelationshipMappingTree from '@/components/CRMRelationships/CRMRelationshipMappingTree.vue'
import GenerateModal from '@/components/DocumentContract/GenerateModal.vue'
import crmRelationshipMappingService from '@/services/crmRelationshipMapping'
import { swalConfirmDeleteOption } from '@/helpers/app'

export default {
  components: {
    CRMRelationshipMappingForm,
    CRMRelationshipMappingTree,
    ImexActionsDropdown,
    ImexAvailableExportsDropdown,
    GenerateModal,
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    entityId: {
      type: [String, Number],
      required: true,
    },

    resourceName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      Entity,
      GenericTabs,
      showForm: false,
      resourceId: null,
      loading: false,
      items: [],
      currentPage: 1,
      total: 10,
      perPage: 25,
      data: null,
      tableColumns: [],
      showTree: false,
      onExport: false,
    }
  },

  computed: {
    canLink() {
      return this.$can('Update', 'CRM')
    },

    canUpdate() {
      return this.$can('Update', 'CRM')
    },

    dataMeta() {
      const localItemsCount = this.items.length

      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.total,
      }
    },
  },

  watch: {
    '$route.params': {
      handler(params) {
        const tabs = {
          [Entity.CONTACT]: GenericTabs.CONTACTS,
          [Entity.COMPANY]: GenericTabs.COMPANIES,
        }

        if (params.tab === tabs[this.type]) {
          this.getAllMappings()
        }
      },

      immediate: true,
    },

    '$route.query': {
      handler(query) {
        if (this.showTree) {
          this.$router.push()
        }
      },
      immediate: true,
    },
    resourceName() {
      this.tableColumns[0].label = this.resourceName
    },
  },

  created() {
    this.tableColumns = [
      {
        key: 'e1CrmRelationshipName',
        label: this.resourceName,
        class: 'disabled',
        sortable: true,
      },
      {
        key: 'e2Name',
        label: this.type === Entity.CONTACT ? 'Contact' : 'Company',
        class: 'disabled',
        sortable: true,
      },
      {
        key: 'e2CrmRelationshipName',
        label: 'Relationship',
        class: 'disabled',
        sortable: true,
      },
      { key: 'actions', class: 'text-center disabled' },
    ]

    if (this.isCompanyTable()) {
      this.tableColumns.splice(1, 0, {
        key: 'varKontakt',
        label: 'Var Kontakt',
        class: 'disabled',
        sortable: true,
      })
    }
  },
  methods: {
    edit(item) {
      this.data = item
      this.resourceId = item.mappingUuid
      this.companyId = item.mappingUuid
      this.showForm = true
    },
    proceedGenerate() {

    },
    openModal() {
      this.$refs.genModal.openModal({
        open: true,
        generateAllRelation: true,
      })
    },
    confirmRemoval(item) {
      if (!this.canUpdate) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          await this.$async(crmRelationshipMappingService.unlink(item.mappingUuid))

          this.getAllMappings()
        }
      })
    },

    async getAllMappings() {
      this.loading = true
      this.showTree = this.$route.query.tree === 'true'
      if (this.showTree) {
        this.loading = false
        return
      }
      const { page, hierarchy } = this.$route.query
      this.currentPage = page || 1

      const query = {
        page: page ? page - 1 : 0,
        size: 25,
        hierarchy,
      }
      const { response } = await this.$async(crmRelationshipMappingService.getAllByEntity(this.type, this.$route.params.id, query))
      this.total = response.data.filteredItemsCount
      this.items = response.data

      if (this.isCompanyTable()) {
        const varKontaktReq = {
          companyIds: [],
        }
        this.items.forEach(mapping => {
          varKontaktReq.companyIds.push(mapping.e1Uuid === this.$route.params.id ? mapping.e2Uuid : mapping.e1Uuid)
        })
        const ress = await this.$async(crmRelationshipMappingService.getVarKontakt(varKontaktReq))
        const varKontaktResponse = ress.response.data
        this.items.forEach((i, ctr) => {
          const iUuid = i.e1Uuid === this.$route.params.id ? i.e2Uuid : i.e1Uuid
          this.items[ctr].varKontakt = varKontaktResponse[iUuid]
        })
      }

      this.loading = false
    },

    getSide(mapping) {
      return mapping.counterPart1Details?.tableUuid === this.entityId ? 2 : 1
    },
    async runExport() {
      this.onExport = true
      const { hierarchy } = this.$route.query
      const companyName = ''
      const query = {
        companyName,
        hierarchy,
      }
      await this.$async(crmRelationshipMappingService.initiateExport(this.type, this.$route.params.id, query))
      this.onExport = false
    },
    handlePageChange(page) {
      if (!page || page === parseInt(this.$route.query.page)) {
        return
      }

      this.updateRouteQuery({ ...this.$route.query, page })
    },
    isCompanyTable() {
      return this.type === 'company'
    },
    toggleForm(resourceId = null) {
      this.showForm = true
      this.resourceId = resourceId
    },

    toggleShowTreeRelationship() {
        this.showTree = !this.showTree
        this.$router.push({ query: { tree: this.showTree } })
    },
    getStatusLabel(s) {
      const statusLabel = {
        active: 'Active', inactive: 'Inactive', all: 'All',
      }
      return statusLabel[s]
    },
    getHierarchyLabel(s) {
      const hierarchyLabel = {
        ALL: 'All', PARENT: 'Parent', CHILD: 'Subsidiary', SAME_LEVEL: 'Same Level',
      }
      return hierarchyLabel[s]
    },
    setStatusFilter(q) {
        this.$router.push({ query: { status: q, tree: true } })
    },
    setHierarchyFilter(q) {
        this.$router.push({ query: { hierarchy: q, tree: false } })
    },
    updateRouteQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: { tab: this.$route.params.tab },
        query,
      })
    },
  },
}
</script>
