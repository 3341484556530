<template>
  <apex-chart
    type="treemap"
    :series="series"
    :options="chartOptions"
    height="95%"
  />
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { mixins } from 'vue-chartjs'

const { reactiveProp } = mixins
export default {
  components: {
    ApexChart,
  },
  mixins: [reactiveProp],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          treemap: {
            distributed: true,
          },
        },
      },
    }
  },
  watch: {
    chartData: {
      handler(newVal) {
        if (newVal.datasets && newVal.datasets.length >= 1) {
          this.series.length = 0
          const elements = []
          for (let index = 0; index < newVal.labels.length; index++) {
            const element = {
              x: newVal.labels[index] != null ? newVal.labels[index] : 'NO_VALUE',
              y: newVal.datasets[0].data[index],
            }
            elements.push(element)
          }
          const seriesData = {
            data: elements,
          }
          this.series.push(seriesData)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style>
.apexcharts-tooltip {
  background: #f3f3f3;
  color: rgb(59, 59, 58);
}
</style>
