import { apiHelper } from '@/helpers/apiHelper'
import { stringify as toQuery } from 'query-string'
import API from '@axios'

const API_URL = '/api/core/todo'

export default {
  ...apiHelper(API_URL),

  store: (payload, isCrm) => API.post(`${API_URL}/${isCrm ? 'crm' : ''}`, payload),
  update: (id, payload, isCrm) => API.put(`${API_URL}/${id}/${isCrm ? 'crm' : ''}`, payload),

  saveSettings(groupUuidKey, refIdStart, showUniversalFields) {
    return API.put(`${API_URL}/setting`, {
      groupUuidKey, refIdStart, showUniversalFields,
    })
  },
  getSettings() {
    return API.get(`${API_URL}/setting`)
  },
  getCustomFields(query) {
    return API.get(`${API_URL}/custom_fields?${toQuery(query)}`)
  },
  searchUser(query, isCrm = false) {
    return API.get(`${API_URL}/users/autocomplete/${isCrm ? 'crm' : ''}?${toQuery(query)}`)
  },
  searchCrm(schemaName, tableName, query) {
    return API.get(`${API_URL}/${schemaName}/${tableName}/autocomplete?${toQuery(query)}`)
  },
  searchMetastatus(type, isCrm = false) {
    return isCrm ? API.get(`${API_URL}/metastatus/crm/${type}`)
      : API.get(`${API_URL}/metastatus/${type}`)
  },

  getOneMetaStatus(schemaName, tableName) {
    return API.get(`/api/metastatus/status/${schemaName}/${tableName}`)
  },
  getCompanyContacts(id, query, isCrm = false) {
    return API.get(`${API_URL}/company/${id}/contact/${isCrm ? 'crm' : ''}?${toQuery(query)}`)
  },
  getHistory(id, payload = {}) {
    return API.get(`${API_URL}/${id}/history?${toQuery(payload)}`)
  },

  getAttached: ({ entityId, tableName }, query = { page: 0, size: 25 }) => API.get(`${API_URL}/${tableName}/${entityId}?${toQuery(query)}`),

  getOneByEntity: ({ schemaName, id }) => API.get(`${API_URL}/${id}/${schemaName}`),

  removeWithDocumentsAndNotes(id, query) {
    return API.delete(`${API_URL}/${id}/attachment?${toQuery(query)}`)
  },

  updateByEntity: (schemaName, entityType, entityId, id, payload) => API.patch(`${API_URL}/${schemaName}/${entityType}/${entityId}/${id}`, payload),
  createByEntity: (schemaName, entityType, entityId, payload) => API.post(`${API_URL}/${schemaName}/${entityType}/${entityId}`, payload),

  deleteWithDocumentsAndNotesByEntity({ schemaName, id }, query) {
    return API.delete(`${API_URL}/${id}/attachment/${schemaName}?${toQuery(query)}`)
  },

  deleteByEntity({ schemaName, id }, query) {
    return API.delete(`${API_URL}/${id}/${schemaName}?${toQuery(query)}`)
  },

  getUsersWithTodo() {
    return API.get(`${API_URL}/users/autocomplete/assigned`)
  },
}
