import * as yup from 'yup'
import CustomFieldTypes, { customFieldValidations } from '@/constants/customFieldTypes'

export const customEntityDataSchema = customFields =>
  yup.object().shape({
    customFields: yup.object().shape({
      ...customFields.reduce((acc, customField) => {
        const validation = customFieldValidations(yup)[customField.fieldType]
        if (validation) {
          if (customField.required) {
            if (customField.fieldType === CustomFieldTypes.Percent) {
              acc[`___${customField.key}`] = validation().required()
            } else {
              acc[customField.key] = validation().required()
            }
          } else if (customField.fieldType !== CustomFieldTypes.Percent) {
            acc[customField.key] = validation()
          }
        }
        return acc
      }, {}),
    }),
  })
