<template>
  <div>
    <b-sidebar
      :visible="visible"
      bg-variant="white"
      no-close-on-backdrop
      no-close-on-esc
      no-header
      right
      backdrop
      shadow
    >
      <div class="form-sidebar d-flex flex-column">
        <div class="form-sidebar__header d-flex justify-content-between align-items-center p-1">
          <div class="d-flex align-items-center">
            <h1 class="form-sidebar__header-title m-0 ml-1 text-uppercase">{{ $t(formTitle) }}</h1>
          </div>
          <button
            class="btn btn-icon rounded-circle btn-close"
            @click="$emit('close')"
          >
            <feather-icon
              icon="XIcon"
              class="tw-text-white"
            />
          </button>
        </div>
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
          style="flex: 1"
        >
          <div
            v-show="!loading"
            class="p-2"
            style="overflow-y: auto; height: calc(100vh - 111px)"
          >
            <div
              v-for="(field, index) of fields"
              :key="index + '-' + field.label"
            >
              <span class="text-sm">{{ $t(field.label) }}</span>
              <p class="font-weight-bold">
                <prozess-resource-value
                  :type="field.fieldType"
                  :value="data[field.key]"
                />
              </p>
            </div>
          </div>
        </b-overlay>
        <div
          spinner-variant="primary"
          rounded="sm"
          variant="transparent"
          class="d-flex flex-column"
        >
          <div class="form-sidebar__footer d-flex justify-content-start align-items-center p-1">
            <button
              class="btn btn-dark"
              @click="$emit('close')"
            >
              {{ $t('Close') }}
            </button>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import todoService from '@/services/todo'

const defaultFields = [
  { label: 'Title', key: 'title' },
  { label: 'Company', key: 'companyName' },
  { label: 'Contact', key: 'contactName' },
  { label: 'Status', key: 'statusName' },
  { label: 'Priority', key: 'priorityName' },
  { label: 'Category', key: 'categoryName' },
  { label: 'Source', key: 'sourceName' },
  { label: 'User', key: 'assignedUserName' },
  { label: 'Description', key: 'description' },
  { label: 'Received Date', key: 'receivedDate', fieldType: 'DATE' },
  { label: 'Due Date', key: 'dueDate', fieldType: 'DATE' },
]

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      customFields: [],
      fields: [],
    }
  },
  computed: {
    formTitle() {
      return this.$t('To Do')
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getOne()
      }
    },
  },
  methods: {
    async getOne() {
      this.data = {}
      this.fields = []
      this.loading = true

      const { response } = await this.$async(todoService.getOneByEntity({ id: this.resourceId, schemaName: 'crm' }))
      const { data } = response

      this.fields = [...defaultFields, ...data.customFieldMetadata.sort((a, b) => b.favorite - a.favorite)]

      this.data = {
        contactName: `${data.contactFirstName} ${data.contactLastName}`,
        ...data.customFieldMetadata.reduce((acc, customField) => {
          const result = data.customFieldData.find(item => customField.id === item.metadataId)
          if (result) {
            acc[customField.key] = result.value
          }
          return acc
        }, {}),
        ...Object.keys(data).reduce((acc, key) => {
          acc[key] = data[key]
          if (acc[key] === 'null') {
            acc[key] = null
          }
          return acc
        }, {}),
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.text-sm {
  font-size: 0.9rem;
  color: $colour--text-muted;
}
</style>
