import { shapeSchemaByMetadata } from '@/helpers/field'

export const contactSchema = shapeSchemaByMetadata

export const companyContactSchema = shapeSchemaByMetadata

export const companyContactRelSchema = shapeSchemaByMetadata

export const activitySchema = shapeSchemaByMetadata

export default {
  contactSchema,
  companyContactSchema,
  companyContactRelSchema,
  activitySchema,
}
