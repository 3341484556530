import * as yup from 'yup'

export const filterFormSchema = yup.object().shape({
  condition: yup.object().shape({
    label: yup.string().required(),
  }),
  // selectedUser: yup.object().when(['userAccessible', 'matchingFields'], {
  //   is: (userAccessible, matchingFields) => !userAccessible && matchingFields.length,
  //   then: yup.object().shape({
  //     label: yup.string().required(),
  //   }),
  // }),
  value: yup.string().when(['condition.label', 'selectedUser'], {
    is: (val, selectedUser) => val !== 'ILIKE' && val !== 'IS NOT NULL' && val !== 'IS NULL' && val !== 'IS TRUE' && val !== 'IS NOT TRUE' && val && !selectedUser.key,
    then: yup.string().nullable().required('Required'),
  }),

})
