<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    form-icon="LinkIcon"
    :form-title="`${$t('Search and Link')} ${relatedEntityName}`"
    :loading="loading"
    :saving="saving"
    :saving-disabled="!$lodash.get(form, 'entity.value')"
    save-icon="LinkIcon"
    save-label="Link"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <entity-dropdown-search
        v-model="form.entity"
        :options="options"
        :placeholder="`${$t('Search for')} ${relatedEntityName}`"
        :entity-type="relatedEntityType"
        :schema="relatedSchema"
        :columns="relatedEntityInfo.displayableOnly"
        :loading="searching"
        @search="handleSearch"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import dataService from '@/services/data'
import EntityDropdownSearch from '@/views/shared/CustomEntity/EntityDropdownSearch.vue'

const defaultForm = { entity: null, entityId: null }

export default {
  components: {
    EntityDropdownSearch,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: 'linkEntityForm',
    },

    owningSchema: {
      type: String,
      default: null,
    },

    owningEntityType: {
      type: String,
      default: null,
    },

    owningEntityId: {
      type: [Number, String],
      default: null,
    },

    relatedSchema: {
      type: String,
      default: null,
    },

    relatedEntityType: {
      type: String,
      default: null,
    },

    relatedEntityInfo: {
      type: Object,
      default: null,
    },

    relatedEntityName: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      resource: {},
      form: this.$lodash.cloneDeep(defaultForm),
      loading: false,
      searching: false,
      saving: false,
      options: [],
      timeout: null,
    }
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.reset()
      this.$emit('close')
    },

    handleSearch(query) {
      this.doDebounce('timeout', async () => {
        query = query.trim()
        if (query.length < 2) {
          this.options = []
          return false
        }

        this.searching = true
        const { response } = await this.$async(
          dataService.autoComplete(
            {
              ...this.$lodash.pick(this, [
                'owningSchema',
                'owningEntityType',
                'owningEntityId',
                'relatedSchema',
                'relatedEntityType',
              ]),
            },
            { query },
          ),
        )

        this.options = this.$lodash.get(response, 'data', [])
        this.searching = false
      })
    },

    reset() {
      this.form = this.$lodash.cloneDeep(defaultForm)
      this.loading = false
      this.saving = false
    },

    async save() {
      const { error } = await this.$async(
        dataService.attach({
          ...this.$lodash.pick(this, [
            'owningSchema',
            'owningEntityType',
            'owningEntityId',
            'relatedSchema',
            'relatedEntityType',
          ]),
          relatedEntityId: this.form.entity.value,
        }),
      )

      if (!error) {
        this.$emit('saved')
        this.close()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
