<template>
  <div class="prozess-chart-container">
    <component
      :is="findType(type)"
      :chart-data="finalizeData"
      :options="chartOptions"
      :style="chartStyle"
    />
  </div>
</template>

<script>
import { graphMixin } from '@/mixins/dashboard'
import BarChart from '@/components/chart/BarChartApex.vue'
import LineChart from '@/components/chart/LineChart.vue'
import PieChart from '@/components/chart/PieChartApex.vue'
import TreemapChart from '@/components/chart/TreemapChartApex.vue'

export default {
  components: {
    BarChart,
    PieChart,
    LineChart,
    TreemapChart,
  },

  mixins: [graphMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      default: () => {},
    },

    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }),
    },
  },

  data() {
    return {
      dataCollection: null,
    }
  },

  computed: {
    chartStyle() {
      return {
        position: 'relative',
        height: '500px',
      }
    },
  },

  watch: {
    chartData: {
      handler(newVal, oldVal) {
        if (newVal) this.getData()
      },
      immediate: true,
    },
  },

  methods: {
    findType(type) {
      switch (type) {
        case 'BAR':
          return 'bar-chart'
        case 'LINE':
          return 'line-chart'
        case 'PIE':
          return 'pie-chart'
        case 'TREE':
          return 'treemap-chart'

        default:
          return false
      }
    },
  },
}
</script>

<style lang="scss">
.prozess-chart-container {
  width: 85%;
  margin: auto;
}
</style>
