export const formatAsRelationshipOptions = relationships => relationships.reduce((acc, item) => {
  acc.push({
    label: item.name,
    side: 1,
    value: item.uuid,
  })
  return acc
}, [])

export const formatAsPayloadForMapping = (
  connectingEntityId,
  connectedEntityId,
  relationshipSide,
) => (relationshipSide === 1
  ? {
    entity1UuidKey: connectedEntityId,
    entity2UuidKey: connectingEntityId,
  } : {
    entity1UuidKey: connectingEntityId,
    entity2UuidKey: connectedEntityId,
  }
)
