import API from '@axios'
import { stringify } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'

const API_URL = '/api/crm/crm_relationship_mapping'

export default {
  ...apiHelper(API_URL),

  list(query = {}) {
    return API.get(`${API_URL}/link?${stringify(query)}`)
  },

  link(id, payload) {
    return API.post(`${API_URL}/link`, payload)
  },

  updateLink(id, payload) {
    return API.patch(`${API_URL}/link/${id}`, payload)
  },

  unlink(id) {
    return API.patch(`${API_URL}/unlink/${id}`)
  },

  getOne(id, query = {}) {
    return API.get(`${API_URL}/link/${id}?${stringify(query)}`)
  },

  getAllByEntity(crmType, id, query = {}) {
    return API.get(`${API_URL}/${crmType}/${id}?${stringify(query)}`)
  },

  initiateExport(crmType, id, query) {
    return API.get(`${API_URL}/${crmType}/${id}/export?${stringify(query)}`)
  },

  getTreeByEntity(crmType, id, query) {
    return API.get(`${API_URL}/${crmType}/${id}/tree?${stringify(query)}`)
  },
  getVarKontakt(varKontaktReq) {
    return API.post('/api/crm/company/var-kontakt', varKontaktReq)
  },
}
