<template>
  <div class="kpi-widget-container">
    <div v-if="data" class="kpi-widget-container__title">
      {{ $t('KPI Widget') }}
    </div>
    <div
      class="kpi-widget"
      :style="{ background: data && 'var(--colour--dark)' }"
    >
      <div class="kpi-widget__body">
        <span class="kpi-widget__body--title">
          {{ getResult().toLocaleString('en-US') || '' }}
        </span>
        <span v-if="selectedKpi" class="kpi-widget__body--subtitle">
          {{ selectedKpi?.type?.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedUserFilter: '',
    }
  },
  computed: {
    ...mapState({
      data: ({ widget }) => widget.previewData,
      selectedKpi: ({ widget }) => widget.widgetValue,
      columns: ({ widget }) => widget.columns,
    }),
  },

  methods: {
    getResult() {
      return this?.data?.result?.count ?? this?.chartData?.result?.count ?? 0
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.kpi-widget-container {
  width: 100%;
  height: 90%;
  color: var(--colour-text-body);
  &__title {
    font-weight: 400;
    border-bottom: 1px solid var(--colour--input-border);
    font-size: 1.6rem;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .kpi-widget {
    padding: 10px;
    min-height: 200px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &__header {
      display: flex;
      justify-content: flex-end;
    }

    &__body {
      &--title {
        font-size: 5.5rem;
        font-weight: bold;
      }
      &--subtitle {
        color: var(--colour--text-muted);
      }
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
